import { booleanAttribute, Component, Input } from '@angular/core';
import { TendersActionService } from '../../services/tenders-action.service';

@Component({
  selector: 'app-tenders-action-toggle',
  templateUrl: './tenders-action-toggle.component.html',
  styleUrls: ['./tenders-action-toggle.component.scss']
})
export class TendersActionToggleComponent {
  @Input({transform: booleanAttribute}) disabled: boolean = false;

  constructor(protected tendersActionService: TendersActionService) {
  }
}
