import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TendersSearchService } from '../../services/tenders-search.service';
import { DisplayItem, DropdownItem } from '../../../common-explain/components/ex-dropdown/ex-dropdown.component';
import { StatusItem } from '../../models/tender-status';
import { UserSettingsService } from '../../../common-explain/services/user-settings.service';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filter-status.component.scss']
})
export class FilterStatusComponent implements OnChanges {
  @Input({required: true}) tendersSearchService!: TendersSearchService;
  @Input() items: DisplayItem<StatusItem>[] = [];
  selectedItems: DisplayItem<StatusItem>[] = [];
  defaultStatuses = ['opportunite', 'a_creuser', 'go', 'aucun'];

  constructor(private userSettingsService: UserSettingsService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      const storedFilter = this.userSettingsService.get('board-status-filter');
      let newFilter;
      if (storedFilter) {
          newFilter = this.items.filter((item) => storedFilter.includes(item.value.id));
      }
      else newFilter = this.items
        .filter((item) => this.tendersSearchService.selectedStatusIds?.includes(item.value.id))
        .filter((item) => this.defaultStatuses.includes(item.value.name ?? ''));
      if (!isEqual(newFilter, this.selectedItems)) {
        this.selectedItems = newFilter;
        if (this.selectedItems.length) this.updateService();
        else this.tendersSearchService.selectedStatusIds = this.items.map(i => i.value.id);
      }
    }
  }

  countOf(status: DisplayItem<StatusItem>) {
    return this.tendersSearchService.statusCounts?.find((s) => s.statusId === status.value.id)?.count ?? undefined;
  }

  computeStatusBackground(item: DisplayItem<StatusItem>) {
    return `color-mix(in srgb, ${item.value.color || 'transparent'} 16%, transparent)`;
  }

  removeStatus(item: DisplayItem<StatusItem>) {
    if (this.tendersSearchService.selectedStatusIds) {
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      this.updateService();
      this.userStatusFilter = this.tendersSearchService.selectedStatusIds
      if (this.tendersSearchService.selectedStatusIds.length === 0) {
        this.tendersSearchService.selectedStatusIds = this.items.map(i => i.value.id);
      }
    }
  }
  addStatus(event: DropdownItem | null) {
    if (event) {
      this.selectedItems.push(event);
      this.updateService();
      this.userStatusFilter = this.selectedItems.map(i => i.value.id);
    }
  }

  updateService() {
    this.tendersSearchService.selectedStatusIds = this.selectedItems.map(item => item.value.id);
  }

  get availableItems() {
    return this.items.filter((item) =>
      !this.selectedItems.map(item => item.value.id).includes(item.value.id));
  }

  get availableDefaultItems() {
    return this.items.filter((item) =>
      item.value.status_type === 'default' && !this.selectedItems.map(item => item.value.id).includes(item.value.id));
  }

  get availableCustomItems() {
    return this.items.filter((item) =>
      item.value.status_type === 'custom' && !this.selectedItems.map(item => item.value.id).includes(item.value.id));
  }

  set userStatusFilter(value: (number | null)[]) {
    this.userSettingsService.set('board-status-filter', value);
  }
}
