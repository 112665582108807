import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDepartmentNumbers',
  standalone: true
})
export class RemoveDepartmentNumbersPipe implements PipeTransform {
  transform(value: string): string {
    // Supprime les groupes de nombres entre parenthèses uniquement à la fin de la chaîne
    return value.trim().replace(/\./, '').replace(/\s*\([0-9,\s]+\)$/, '').trim();
  }
}
