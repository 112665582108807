import { TreeviewItem } from '../../lib/ngx-treeview';
import { Territory } from '../../models/territory/territory';
import { TerritoryKind } from '../../core/territory-kind/territory-kind.enum';

export const TerritoryHelper = {
  transferToTreeview,
  transferToList
};

function containsTerritoryOutsideDepartment(element: Territory) {
  return (element.name === 'outside_department') || (element.name === 'outside_Metropolis_Greater_Paris') ;
}

function getTerritoryChildren(territory: Territory) {
  let children = territory.territories.filter((child) => !child.isSyndicate);

  if (territory.kind === TerritoryKind.EPCI && territory.territories.length >= 2 && territory.territories[1].name === 'outside_department') {
    const territory_outside_department = new Territory({
      name: 'outside_department',
      uid: '',
      territories: [],
      search_key: '',
      kind: '',
      code: '',
      outside_department_count: territory.territories[1].territories.length
    });

    if (territory.territories[0].territories[0].name === 'Paris') {
      territory_outside_department.name = 'outside_Metropolis_Greater_Paris';
    }
    if (!territory.territories[0].territories.some(containsTerritoryOutsideDepartment)) {
      territory.territories[0].territories.push(territory_outside_department);
    }

    children = territory.territories[0].territories;

  }
  return children;
}
function transferToTreeview(
  territories: Array<Territory>,
  isRoot: boolean,
  collapsed = false,
  checked: boolean | Array<string> = true
): TreeviewItem[] {
  return territories.map((territory) => {
    const children = getTerritoryChildren(territory);
    const uid = territory.uid;
    return new TreeviewItem({
      text: mapDepartementName(territory),
      value: territory.uid,
      isRoot: isRoot,
      collapsed: collapsed && !isRoot,
      outside_department_count: territory.outside_department_count,
      infos: {
        kind: territory.kind,
        subkind: territory.subkind,
        code: territory.code,
        articleCount: territory.articleCount,
        adminDocCount: territory.adminDocCount
      },
      checked: typeof checked === 'boolean' ? checked : checked.includes(uid),
      children: transferToTreeview(children, false, collapsed, checked)
    });
  });
}

function mapDepartementName(territory: Territory) {
  if (territory.kind === TerritoryKind.DEPARTMENT) {
    return territory.name + ` (${territory.region?.name})`;
  } else return territory.name
}

function transferToList(territories: Array<Territory>): Set<string> {
  const territoriesList = new Set<string>();
  territories.forEach((territory) => {
    if (territory.uid) {
      territoriesList.add(territory.uid);
    }
    const children = getTerritoryChildren(territory);
    transferToList(children).forEach(c => territoriesList.add(c));
  });
  return territoriesList;
}
export function territoriesToUIDArray(territories: Array<Territory>) : Array<any>{
  return territories.reduce((prev: Array<string>, territory: Territory) => [...prev, territory.uid, ...territoriesToUIDArray(territory.territories)], []);
}

export function makeSyndicatesTreeview(tree: {[p: string]: Territory[]}, rootText= 'All') {
  const scopeFilter = Object.entries(tree).map(
    ([scope, syndicates]) => new TreeviewItem({
      text: scope,
      value: '',
      checked: true,
      collapsed: true,
      infos: {type: 'syndicates'},
      children: syndicates.map((syndicate) => new TreeviewItem({
        text: syndicate.name,
        value: syndicate.uid,
        checked: true,
        infos: {
          type: 'syndicates',
          fullname: syndicate.syndicateName
        }
      }))
    }));
  return [new TreeviewItem({
    text: rootText,
    value: '',
    isRoot: true,
    checked: true,
    infos: {type: 'syndicates'},
    children: scopeFilter
  })];
}

export function departmentContainsEpt(departmentCode: string) {
  const departments_with_epts = ['95', '94', '93', '92', '91'];
  return departments_with_epts.includes(departmentCode);
}

export function territoriesContainsEpt(territories: Array<{territory: string, values: []}>): boolean {
  for (const territory of territories) {
    const departmentCode = territory.territory?.slice(-2);
    if (departmentContainsEpt(departmentCode) ) {
      return true;
    }
  }
  return false;
}
