import { DropdownItem } from "../../../common-explain/components/ex-dropdown/ex-dropdown.component";
import { StatusItem } from "../../models/tender-status";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { CurrencyPipe } from "@angular/common";
import {environment} from "../../../../environments/environment";

export async function convertTenderToDeltas(tender: any, status: DropdownItem<StatusItem>[], document: any, inquirers: string, department: string, translate: TranslateService): Promise<any> {
  const ops: any[] = [];

  // Fonction utilitaire pour ajouter un saut de ligne
  const addNewLine = () => ops.push({ insert: '\n' });
  const trad = async (string: string) => {
    return firstValueFrom(translate.get(string));
  };

  // Titre avec style gras
  ops.push(
    { insert: tender.title.replace(/<\/?em>/g, ''), attributes: { bold: true, underline: true, link: `${environment.appBaseUrl}/tenders/search/${tender.id}` } }
  ); addNewLine();

  // Demandeur • Territoires
  if (inquirers.length > 0) {
    ops.push({ insert: `${inquirers.replace(/<\/?em>/g, '')} • `});
  }
  ops.push(
    { insert: `${department}` }
  ); addNewLine();

  // Statut
  ops.push(
    { insert: await trad('tenders.market-watches.modal-share-watch.status') },
    { insert: status.find((item) => item.value.id === tender.statusId)?.value.displayName ||
        await trad('tenders.market-watches.modal-share-watch.none') },
    { insert: ' • ' + await trad('tenders.market-watches.modal-share-watch.assignment') },
    { insert: document.assignedUser || await trad('tenders.market-watches.modal-share-watch.nobody') },
  ); addNewLine();

  // Date de publication
  ops.push(
    { insert: await trad('tenders.market-watches.modal-share-watch.avis')},
    { insert: tender.tenderType ? (await trad('tenders.badge.' + tender.tenderType)).toLowerCase() : '' },
    { insert: await trad('tenders.market-watches.modal-share-watch.published-on') },
    { insert: tender.indexationDate ? new Date(tender.indexationDate).toLocaleDateString() : '' },
  ); addNewLine();

  // Date de clôture
  if (tender.tenderType !== 'ATTRIBUTION') {
    ops.push(
      { insert: await trad('tenders.market-watches.modal-share-watch.closing-date') },
      {
        insert: tender.closingDate
          ? `${new Date(tender.closingDate).toLocaleDateString()}${await trad('tenders.market-watches.modal-share-watch.at-time')} ${new Date(tender.closingDate).toLocaleTimeString('fr-FR', {
              hour: '2-digit', 
              minute: '2-digit', 
              hour12: false
            })}`
          : await trad('tenders.not-detected-f')
      },
    ); addNewLine();
  }

  // Ajouter le code lié à la fin de marché 
  if (tender.estimatedEndDate) {
    ops.push(
      {insert: await trad('tenders.market-watches.modal-share-watch.estimated-end-date')},
      {insert: ' ' + new Date(tender.estimatedEndDate).toLocaleDateString('fr-FR', {month: 'short', year: 'numeric'}) + '\n'},
    )
  }

  if (tender.assignees?.length) {
    ops.push({insert: await trad('tenders.market-watches.modal-share-watch.assigned-to')})
    ops.push({insert: tender.assignees.join(', ')})
  }

  if (tender.assignees?.length && tender.amount) {
    ops.push({insert: ' • '});
  }

  if (tender.amount) {
    const currencyPipe = new CurrencyPipe('fr');
    ops.push({insert: await trad('tenders.market-watches.modal-share-watch.amount')});
    if (tender.amount.total) {
      ops.push({insert: currencyPipe.transform(tender.amount.total, 'EUR', 'symbol', '.0', 'fr')});
    } else if (tender.amount.min) {
      ops.push({insert: currencyPipe.transform(tender.amount.min, 'EUR', 'symbol', '.0', 'fr') + ' (min.)'});
      ops.push({insert: tender.amount.max ? ' - ' + currencyPipe.transform(tender.amount.max, 'EUR', 'symbol', '.0', 'fr') + ' (max.)' : ''});
    } else if (tender.amount.max) {
      ops.push({insert: currencyPipe.transform(tender.amount.max, 'EUR', 'symbol', '.0', 'fr') + ' (max.)'});
    }
    addNewLine();
  }

  // Liens
  ops.push(
    {
      insert: await trad('tenders.market-watches.modal-share-watch.explain-link'),
      attributes: {link: `${environment.appBaseUrl}/tenders/search/${tender.id}`}
    }
  );

  const noDceAndIsAvisSource = !tender.dceProjectUid && tender.dceDocuments?.length === 1 && tender.dceDocuments[0].publicationType?.includes('ao_avis_source');
  if (!noDceAndIsAvisSource) {
    let doc_uid = '';
    const firstNonSourceAvis = tender.dceDocuments?.find((elm: {publicationType: string}) => !elm.publicationType?.includes('ao_avis_source'));
    if (firstNonSourceAvis) doc_uid = firstNonSourceAvis ? firstNonSourceAvis.uid : tender.id;
    ops.push(
      {insert: ' - '},
      {
        insert: await trad('tenders.market-watches.modal-share-watch.' + (tender.dceProjectUid ? 'dce-download-link' : 'avis-download-link')),
        attributes: {link: `${environment.explainDceExportWorkerUrl}?project_uid=${tender.id}` + (tender.dceProjectUid ? `` : `&doc_uid=${doc_uid}`)}
      }
    );
  }

  if (tender.dceUrl) {
    ops.push(
      { insert: ' - ' },
      { insert: await trad('tenders.market-watches.modal-share-watch.inquirer-link'), attributes: { link: tender.dceUrl }, className: 'test' }
    );
  }

  addNewLine();

  return {
    ops: ops
  };

}

export async function getNoteDelta(tender: any, translate: TranslateService): Promise<any> {
  if (!tender.note) { return {ops: []}; }
  return {
    ops: [
      {
        insert: '\n' + await firstValueFrom(translate.get('tenders.market-watches.modal-share-watch.note')) + '\n',
        attributes: {header: 4, bold: true}
      },
      {
        insert: tender.note || await firstValueFrom(translate.get('tenders.market-watches.modal-share-watch.no-note-market')) + '\n\n',
        attributes: {italic: true}
      }
    ]
  }
}
