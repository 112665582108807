<div class="no-select menu-item-container d-flex flex-column align-items-center"
     [routerLink]="menuItemRouterLink"
     (mouseenter)="setPhStyle(true)"
     (mouseleave)="setPhStyle(false)"
     routerLinkActive="router-link-active"
     (isActiveChange)="onRouterLinkActive($event)">
  <div class="icon-container d-flex align-items-center justify-content-center">
    <i class="{{phStyle}} ph-{{iconName}} d-flex align-items-center justify-content-center"></i>
  </div>
  <div *ngIf="label"
    class="icon-label d-flex align-items-center justify-content-center text-center">{{ 'menu.menu-item-label.' + module + '.' + label | translate }}
  </div>
</div>


