<ng-template #actionBar>
  <div *ngIf="tendersActionService.selection && !(tenderEntityService.tenderId$ | async)" class="action-bar"
       [ngClass]="{'disabled': tendersSearchService.inSearch}">
    <ex-button size="small" icon="x" (click)="tendersActionService.toggle(false)"/>
    <div class="selected text-truncate">
      {{ 'tenders.action.selected-tender' + (tendersActionService.selection.length > 1 ? 's' : '') | translate:
      { count: tendersActionService.selection.length } }}
    </div>
    <div class="d-flex gap-2 ms-auto">
      <ex-button type="secondary" variant="tonal" size="small" (click)="targetAction = 'export'"
                 cdkOverlayOrigin #exportTrigger="cdkOverlayOrigin">
        {{ 'tenders.action.export' | translate }}
      </ex-button>
      <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="exportTrigger"
                   [cdkConnectedOverlayOpen]="targetAction === 'export'" (detach)="targetAction = undefined"
                   (overlayOutsideClick)="targetAction = undefined"
                   [cdkConnectedOverlayPositions]="[{
                     originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -4
                   }]">
        <div class="action-submenu ex-shadow">
          <button class="submenu-row" (click)="tendersActionService.exportSelection(tendersSearchService, exportToast)"
               [disabled]="!tendersActionService.selection.length || !!tendersActionService.export"
          >
            <span>{{ ('tenders.action.export-selection') | translate }}</span>
            <span>({{ tendersActionService.selection.length }})</span>
          </button>
          <button class="submenu-row"
                  (click)="tendersActionService.exportAll(tendersSearchService, exportToast)"
                  [disabled]="!tendersSearchService.totalCount || !!tendersActionService.export ||
                    tendersSearchService.totalCount > TENDERS_EXPORT_LIMIT"
                  [ngbTooltip]="exportLimitToast" [openDelay]="500" tooltipClass="simple-tooltip" container="body"
                  placement="bottom auto" [disableTooltip]="tendersSearchService.totalCount <= TENDERS_EXPORT_LIMIT"
          >
            <span>{{ ('tenders.action.export-all') | translate }}</span>
            <span>({{ tendersSearchService.totalCount }})</span>
          </button>
        </div>
      </ng-template>
      <ex-button type="secondary" size="small" (click)="targetAction = 'tag'"
                 cdkOverlayOrigin #tagTrigger="cdkOverlayOrigin"
                 [disabled]="!tendersSearchService.totalCount || tendersSearchService.totalCount > TENDERS_TAG_LIMIT &&
                    tendersActionService.selection.length > TENDERS_TAG_LIMIT"
                 [ngbTooltip]="tagLimitToast" [openDelay]="500" tooltipClass="simple-tooltip my-2"
                 placement="top bottom auto"
                 [disableTooltip]="tendersSearchService.totalCount <= TENDERS_TAG_LIMIT ||
                    tendersActionService.selection.length <= TENDERS_TAG_LIMIT">
        {{ 'tenders.action.tag' | translate }}
      </ex-button>
      <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="tagTrigger"
                   [cdkConnectedOverlayOpen]="targetAction === 'tag'" (detach)="targetAction = undefined"
                   (overlayOutsideClick)="targetAction = undefined"
                   [cdkConnectedOverlayPositions]="[{
                     originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom', offsetY: -4
                   }]">
        <div class="action-submenu ex-shadow">
          <button class="submenu-row" (click)="openTagAction = 'selection'"
               [disabled]="!tendersActionService.selection.length"
               cdkOverlayOrigin #tagSelectionTrigger="cdkOverlayOrigin"
          >
            <span>{{ ('tenders.action.tag-selection') | translate }}</span>
            <span>({{ tendersActionService.selection.length }})</span>
            <i class="ph-bold ph-caret-right ms-auto"></i>
          </button>
          <button class="submenu-row" (click)="openTagAction = 'all'"
                  [disabled]="!tendersSearchService.totalCount || tendersSearchService.totalCount > TENDERS_TAG_LIMIT"
                  cdkOverlayOrigin #tagAllTrigger="cdkOverlayOrigin"
                  [ngbTooltip]="tagLimitToast" [openDelay]="500" tooltipClass="simple-tooltip" container="body"
                  placement="bottom auto" [disableTooltip]="tendersSearchService.totalCount <= TENDERS_TAG_LIMIT"
          >
            <span>{{ ('tenders.action.tag-all') | translate }}</span>
            <span>({{ tendersSearchService.totalCount }})</span>
            <i class="ph-bold ph-caret-right ms-auto"></i>
          </button>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="openTagAction === 'selection' ? tagSelectionTrigger : tagAllTrigger"
            [cdkConnectedOverlayOpen]="!!openTagAction" (detach)="openTagAction = undefined"
            (overlayOutsideClick)="openTagAction = undefined"
            [cdkConnectedOverlayPositions]="[
              { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom', offsetX: 16, offsetY: 8 },
              { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom', offsetX: -16, offsetY: 8 }
            ]">
            <div class="status-list-container ex-shadow">
              <app-tender-status-list [defaultItems]="tendersModuleService.defaultStatusItems"
                                      [customItems]="tendersModuleService.customStatusItems"
                                      (itemSelect)="statusItemSelect($event)"
                                      editable />
            </div>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #exportLimitToast>
    <div class="text-start">{{'tenders.action.export-all-limit' | translate: { limit: TENDERS_EXPORT_LIMIT } }}</div>
    <div class="text-start">{{'tenders.action.export-refine-search' | translate}}</div>
</ng-template>
<ng-template #tagLimitToast>
    <div class="text-start">{{'tenders.action.tag-all-limit' | translate: { limit: TENDERS_TAG_LIMIT } }}</div>
    <div class="text-start">{{'tenders.action.tag-refine-search' | translate}}</div>
</ng-template>

<!--TODO rework toast-->
<ng-template #exportToast let-toast="toast" let-toastService="toastService">
  <div class="awaiting-toast-container no-select">
    <div class="left-part-toast">
      <div class="align-middle justify-content-center">
        <div class="waiting-circle-full animate-spin"></div>
      </div>
    </div>
    <div class="center-part-toast">
      <div class="title color-primary-500 fw-bold">
        <span
          class="d-table-cell align-middle">{{ 'tenders.action.generating-excel' | translate | uppercase }}</span>
      </div>
      <div class="text fst-italic fw-bold color-basic-800">
        <span class="d-table-cell align-middle">{{ 'tenders.action.download-will-soon-start' | translate }}</span>
      </div>
      <div class="cancel-export color-basic-600 fw-bold d-table">
        <span class="d-table-cell align-middle"
              (click)="toastService.remove(toast); tendersActionService.export?.cancel()">
          {{ 'export.awaiting-export-toast-cancel' | translate }}
        </span>
      </div>
    </div>
    <div class="right-part-toast">
      <div class="hide-icon"
           ngbTooltip="{{'export.hide' | translate}}"
           placement="top">
        <mat-icon svgIcon="hide-600" class="icon-20 strict me-2"
                  (click)="toastService.hide(toast,
                                             exportToast,
                                            'toast-export-in-progress toast-shape',
                                            'list-export',
                                            lightToast)"
        >
        </mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #lightToast let-toast="toast" let-toastService="toastService">
  <div class="mini-toast-container d-flex">
    <div class="separator"></div>
    <div class="mini-toast" (click)="toastService.unhide(toast, 'list-export')">
      <div class="mini-waiting-circle d-table-cell align-middle align-content-center">
        <div class="waiting-circle-mini animate-spin"></div>
      </div>
    </div>
  </div>
</ng-template>
