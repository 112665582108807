import { Injectable } from '@angular/core';
import { DbPostSearchSort, PostTenderSearchBody, PostTenderSearchWatchBody, SortFieldEnum, TenderTypesEnum } from '../models/tender-search-body';
import { Tender } from '../models/tender';
import { ApiTenderService } from './api/api-tender.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { SortDirEnum } from '../../common-explain/models/sort';
import { DbMarketWatchFilters } from '../models/market-watch';
import { debounceTime, filter, throttleTime } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { isEqual } from 'lodash';
import { formatDateAsYYYYMMDD, toDayPeriod } from '../../shared/helpers/date-helper';
import { WatchUser } from '../../common-explain/components/ex-watch-users-selector/ex-watch-users-selector.component';
import { StatusCountItem } from "../models/tender-status";

export interface Period {
  from: string,
  to: string
}

export interface TendersFilters {
  territories?: string[];
  topics?: number[];
  text?: string;
  refined_search_text?: string;
  indexation_period?: Period;
  tender_types?: TenderTypesEnum[];
  limit?: number;
  offset?: number;
  sort?: { dir: SortDirEnum, field: SortFieldEnum };
  market_watch_id?: number;
  watch_users?: WatchUser[];
  status_ids?: (number | null)[];
  assigned_user_ids?: number[];
  estimated_end_period?: Period;
}

export enum FilteringPeriodTypeEnum {
  INDEXATION = "indexation_period",
  ESTIMATED_END = "estimated_end_period"
}

const DEFAULT_SORT: DbPostSearchSort = {dir: SortDirEnum.DESC, field: SortFieldEnum.INDEXATION_DATE}

@Injectable({
  providedIn: 'root'
})
export class TendersSearchService {
  public _filters$ = new BehaviorSubject<TendersFilters & { propagate?: boolean, fromFilter?: 'status' | 'offset' | 'limit' | 'sorting'} | null>(null);
  public _tenders$ = new BehaviorSubject<{ data: Tender[], total_count: number } | null>(null);
  public tendersCache = new Map();
  public _cachedPeriod: Period | null = null;
  public _viewMode$ = new ReplaySubject<'preview' | 'watch'>(1);
  public viewMode: 'preview' | 'watch' = 'watch';
  private _error?: string;
  private _nbPages = 0;
  private activeRequest?: Promise<any>;
  public isObsolete = false;
  public statusCounts?: StatusCountItem[];

  constructor(private apiTenderService: ApiTenderService) {
    this._filters$
      .pipe(
        filter((filters) => filters?.propagate ?? true),
        debounceTime(200),
        throttleTime(2000, undefined, {leading: true, trailing: true})
      )
      .subscribe((filters) => {
        if (
          filters?.territories?.length &&
          (filters?.topics?.length || filters?.text?.length || filters?.status_ids?.length) &&
          ((filters.indexation_period?.from && filters.indexation_period.to) || (filters.estimated_end_period?.from && filters.estimated_end_period.to)) &&
          filters.tender_types?.length
        ) {
          const isWatchSearch = this.viewMode === 'watch';
          let searchBody;
          if (filters.market_watch_id && isWatchSearch && filters.indexation_period?.from && filters.indexation_period.to) {
            searchBody = new PostTenderSearchWatchBody(
              filters.topics,
              filters.market_watch_id,
              formatDateAsYYYYMMDD(filters.indexation_period.to),
              filters.offset ?? 0,
              filters.limit ?? 25,
              filters.sort ?? DEFAULT_SORT,
            )
          } else {
            searchBody = new PostTenderSearchBody(
              filters.topics,
              filters.text,
              filters.refined_search_text,
              filters.territories,
              filters.indexation_period,
              filters.estimated_end_period,
              filters.tender_types,
              filters.status_ids,
              filters.assigned_user_ids,
              filters.offset ?? 0,
              filters.limit ?? 25,
              filters.sort ?? DEFAULT_SORT
            )
          }
          if (isWatchSearch) this._cachedPeriod = filters.indexation_period ?? null; // caching indexation period;
          this._error = undefined;
          const tendersRequest = this.apiTenderService.search.retrieveTenders(searchBody)
            .then((res) => {
              if (tendersRequest === this.activeRequest) {
                if (filters.offset) this._tenders$.next({
                  data: [...this._tenders$.value?.data ?? [], ...res.data],
                  total_count: res.total_count
                })
                else this._tenders$.next(res);
                if (isWatchSearch && filters.indexation_period) this.tendersCache.set(filters.indexation_period.to, res);
              }
            })
            .catch((err: HttpErrorResponse) => {
              if (tendersRequest === this.activeRequest) {
                if (!filters.offset) this._tenders$.next({data: [], total_count: 0})
                this._error = err.error
              }
            })
            .finally(() => {
              if (tendersRequest === this.activeRequest) this.activeRequest = undefined;
            });
          this.activeRequest = tendersRequest;
        } else {
          this._tenders$.next(null)
          this.activeRequest = undefined;
        }
      });

    this._viewMode$
      .subscribe((viewMode) => {
        this.viewMode = viewMode;
        this.activeRequest = undefined;
          if (viewMode === 'watch') { // retour au jour J + gestion marchés en cache.
            this._filters$.next(
              {
                ...(this._filters$.value ?? {}),
                indexation_period: this._cachedPeriod ?? toDayPeriod(new Date()),
                offset: 0,
                propagate: false,
                fromFilter: undefined
              });
            this._tenders$.next(this.tendersCache.get(this._cachedPeriod?.to));
          }
          else {
            this._filters$.next(
              {
                ...(this._filters$.value ?? {}),
                indexation_period: toDayPeriod(this._cachedPeriod?.to ?? new Date()),
                offset: 0,
                propagate: false
              })
            this.search();
          }
        }
      );
  }

  get inSearch(): boolean {
    return !!this.activeRequest;
  }

  get tenders(): Tender[] | undefined {
    return this._tenders$.value?.data;
  }

  get totalCount(): number {
    return this._tenders$.value?.total_count ?? 0;
  }

  get filters(): TendersFilters | null {
    return this._filters$.value;
  }

  set filters(value: TendersFilters | null) {
    this._filters$.next({
      ...value
    });
  }

  get selectedTopics(): number[] | undefined {
    return this._filters$.value?.topics
  }

  /** set new value and update filterChanged observable */
  set selectedTopics(value: number[] | null) {
    if (isEqual(this.selectedTopics, value)) return;
    this._filters$.next({...(this._filters$.value ?? {}), topics: value ?? undefined, text: undefined, refined_search_text: undefined, offset: 0,
      propagate: undefined, fromFilter: undefined});
  }

  get searchText(): string | undefined {
    return this._filters$.value?.text;
  }

  /** set new value and update filterChanged observable */
  set searchText(value: string | null) {
    this._filters$.next({...(this._filters$.value ?? {}), topics: undefined, text: value ?? undefined, refined_search_text: undefined, offset: 0,
      propagate: undefined, fromFilter: undefined});
  }

  get refinedSearchText(): string | undefined {
    return this._filters$.value?.refined_search_text;
  }

  set refinedSearchText(value: string | null) {
    this._filters$.next({...(this._filters$.value ?? {}), refined_search_text: value ?? undefined, offset: 0,
      propagate: undefined, fromFilter: undefined});
  }

  get selectedTerritories(): string[] | undefined {
    return this._filters$.value?.territories;
  }

  /** set new value and update filterChanged observable */
  set selectedTerritories(value: string[] | null) {
    if (isEqual(this.selectedTerritories, value)) return;
    this._filters$.next({...(this._filters$.value ?? {}), territories: value ?? undefined, offset: 0, propagate: undefined, fromFilter: undefined});
  }

  get selectedIndexationPeriod(): Period | null {
    return this._filters$.value?.indexation_period ?? null;
  }

  /** set new value and update filterChanged observable */
  set selectedIndexationPeriod(value: Period | null) {
    this._filters$.next({...(this._filters$.value ?? {}), indexation_period: value ?? undefined, estimated_end_period: undefined, offset: 0, propagate: undefined, fromFilter: undefined});
  }

  get selectedEstimatedEndPeriod(): Period | null {
    return this._filters$.value?.estimated_end_period ?? null;
  }

  /** set new value and update filterChanged observable */
  set selectedEstimatedEndPeriod(value: Period | null) {
    this._filters$.next({...(this._filters$.value ?? {}), estimated_end_period: value ?? undefined, indexation_period: undefined, offset: 0, propagate: undefined, fromFilter: undefined});
  }

  get selectedStatusIds(): (number | null)[] | null {
    return this._filters$.value?.status_ids ?? null;
  }

  /** set new value and update filterChanged observable */
  set selectedStatusIds(value: (number | null)[] | null) {
    this._filters$.next({...(this._filters$.value ?? {}), status_ids: value ?? undefined, offset: 0, propagate: undefined, fromFilter: 'status'});
  }

  get selectedUserIds(): number[] {
    return this._filters$.value?.assigned_user_ids ?? [];
  }

  set selectedUserIds(value: number[]) {
    this._filters$.next({...(this._filters$.value ?? {}), assigned_user_ids: value.length ? value : undefined, offset: 0, propagate: undefined, fromFilter: undefined});
  }

  get offset(): number {
    return this._filters$.value?.offset ?? 0;
  }

  set offset(value: number) {
    this._filters$.next({...(this._filters$.value ?? {}), offset: value, propagate: undefined, fromFilter: 'offset'});
  }

  get limit(): number {
    return this._filters$.value?.limit ?? 25;
  }

  set limit(value: number) {
    this._filters$.next({...(this._filters$.value ?? {}), limit: value, propagate: undefined, fromFilter: 'limit'});
  }

  get page(): number {
    return Math.floor(this.offset / this.limit) + 1;
  }

  set page(value: number) {
    this.offset = (value - 1) * this.limit;
  }

  get nbPages(): number {
    return this._nbPages;
  }

  set nbPages(value: number) {
    this._nbPages = value;
  }

  get loadedPage(): number {
    return (this.tenders ? Math.floor(this.tenders?.length / this.limit) : 0);
  }

  get lastPageReached(): boolean {
    return ((this.tenders?.length ?? 0) >= this.totalCount) && !this.inSearch;
  }

  get tenderTypes(): TenderTypesEnum[] | undefined {
    return this._filters$.value?.tender_types;
  }

  set tenderTypes(value: TenderTypesEnum[]) {
    this._filters$.next({...(this._filters$.value ?? {}), tender_types: value, offset: 0, propagate: undefined, fromFilter: undefined});
  }

  get sort(): DbPostSearchSort | undefined {
    return this._filters$.value?.sort ?? DEFAULT_SORT;
  }

  set sort(value: { dir: SortDirEnum, field: SortFieldEnum }) {
    this._filters$.next({...(this._filters$.value ?? {}), sort: value, offset: 0, propagate: undefined, fromFilter: 'sorting'});
  }

  set marketWatchId(value: number) {
    this._filters$.next({...(this._filters$.value ?? {}), market_watch_id: value, offset: 0, propagate: undefined, fromFilter: undefined});
  }

  get watchFilters(): DbMarketWatchFilters | null {
    if (!this._filters$.value?.territories?.length || !this._filters$.value?.topics?.length || !this._filters$.value?.tender_types?.length)
      return null;
    return {
      settings_json: {
        territories: this._filters$.value?.territories ?? [],
        tender_types: this._filters$.value?.tender_types ?? [],
        market_types: []
      },
      topics_ids: this._filters$.value?.topics ?? []
    }
  }

  set watchFilters(value: DbMarketWatchFilters & { market_watch_id?: number, propagate?: boolean, indexation_period?: Period }) {
    this._filters$.next({
      territories: value.settings_json.territories,
      topics: value.topics_ids,
      tender_types: value.settings_json.tender_types,
      indexation_period: value.indexation_period ?? toDayPeriod(new Date()),
      limit: 25,
      offset: 0,
      sort: {dir: SortDirEnum.DESC, field: SortFieldEnum.RELEVANCE},
      market_watch_id: value.market_watch_id,
      propagate: value.propagate,
      fromFilter: undefined
    });
    if (!value.topics_ids.length) this.isObsolete = true;
  }

  get error(): string | undefined {
    return this._error;
  }

  search() {
    this._filters$.next({...this._filters$.value, propagate: undefined, fromFilter: undefined});
  }

  resetSubjects() {
    this._filters$.next(null)
    this._tenders$.next({
      data: new Array<Tender>(),
      total_count: 0
    })
    this._nbPages = 0;
  }

  destroy() {
    this._filters$.complete();
    this._tenders$.complete();
  }

  /**
   * Méthode de mise à jour des compteurs par status.
   * @param statusIds - {from : number, to : number} - id du statut avant et après la mise à jour.
   */
  updateStatusCount(statusIds: {from: number | null, to: number | null}) {
    // On décrémente l'ancien status
    const previousStatusCount = this.statusCounts?.find((s) => s.statusId === (statusIds.from));
    if (previousStatusCount?.count) previousStatusCount.count--;
    // On incrémente le nouveau status
    const newStatusCount = this.statusCounts?.find((s) => s.statusId === statusIds.to);
    if (newStatusCount?.count !== undefined) newStatusCount.count++;
  }
}
