<div class="modal-header">
  @if (updateMode) {
    <!--Modal update mode-->
    <h4 class="modal-title update-modal" id="modal-update-title">
      {{ title | translate }}
    </h4>
  } @else {
    <h4 class="modal-title" id="modal-basic-title">
      {{ title | translate }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()">
    </button>
  }
</div>


@if (isNewFeatureExplainedModal) {
  <!--New feature modal-->
  <div class="modal-body-new-feature d-flex flex-column">
    <ul>
      <li>{{ explanation | translate }}</li>
      <li>{{ explanation2 | translate }}</li>
    </ul>
    <img src="./assets/images/new-feature-modal-regional-council.png" alt="regional-council-feature-explanation">
  </div>
} @else {
  <div class="modal-body d-flex flex-column">

    <div class="fw-bold message">
      {{ content | translate }}
    </div>

    <!--Historic update modal (no precision on new feature)-->
    <div *ngIf="!isNewFeatureExplainedModal" class="fw-bold message">
      {{ content2 | translate }}
    </div>
  </div>
}


<!--Modal update mode /w snooze-->
<div *ngIf="snooze" class="modal-body explanation">
  <div class="message">
    <!--New feature modal (here export)-->
    <span *ngIf="isNewFeatureExplainedModal" [style.font-style]="'normal'">{{ explanation | translate }}</span>
    <!--Historic update modal (no precision on new feature)-->
    <span *ngIf="!isNewFeatureExplainedModal"
          class="text-decoration-underline">{{ explanationUnderline | translate }}</span>
  </div>
</div>

<div *ngIf="!updateMode" class="modal-footer">
  <ng-container *ngIf="!alertMode">
    <button type="button" class="cancel fw-bold"
            (click)="close()">{{ 'button.modal-cancel-no' | translate }}
    </button>
    <button type="button" class="btn confirm-delete d-flex" (click)="cancelConfirm()">
      <div class="icon icon--trash align-self-center"></div>
      <div class="text-uppercase fw-bold align-self-center">
        {{ btnName | translate }}
      </div>
    </button>
  </ng-container>
  <ng-container *ngIf="alertMode">
    <button type="button" class="btn confirm-delete d-flex" (click)="close()">
      <div class="text-uppercase fw-bold align-self-center">
        {{ btnName | translate }}
      </div>
    </button>
  </ng-container>
</div>

<!--Modal update mode /w and w/o snooze-->
<div *ngIf="updateMode" class="modal-footer update-modal">
  <ng-container>
    <button *ngIf="snooze && !isNewFeatureExplainedModal"
            id="{{snoozeButtonClickedId}}"
            class="snooze fw-bold"
            type="button"
            (click)="snoozeAndClose()">{{ 'application-update.modal-snooze' | translate }}
    </button>
    <button *ngIf="!isNewFeatureExplainedModal"
            type="button"
            class="btn confirm-delete d-flex"
            (click)="updateConfirm()">
      <div class="icon icon--refresh align-self-center"></div>
      <div class="text-uppercase fw-bold align-self-center">
        {{ btnName | translate }}
      </div>
    </button>
    <button *ngIf="isNewFeatureExplainedModal"
            type="button"
            class="btn confirm-delete d-flex"
            (click)="onCloseNewFeatureExplainedModal()">
      <div class="text-uppercase fw-bold align-self-center">
        {{ btnName | translate }}
      </div>
    </button>
  </ng-container>
</div>
