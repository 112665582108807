import {
  booleanAttribute,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownItem } from '../../../common-explain/components/ex-dropdown/ex-dropdown.component';
import { StatusItem } from '../../models/tender-status';
import { SearchHelper } from '../../../shared/helpers/search.helper';
import { Subscription } from 'rxjs';
import { CommonExplainModule } from '../../../common-explain/common-explain.module';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RegexpSearch } from '../../../shared/helpers/regex';
import { ApiGroupAccountStatusService } from '../../services/api/group-account-status/api-group-account-status.service';
import { TendersModuleService } from '../../services/tenders-module.service';
import { CdkConnectedOverlay, CdkOverlayOrigin, CdkScrollable } from '@angular/cdk/overlay';
import { AutoselectDirective } from '../../../common-explain/directives/autoselect.directive';
import { ModalConfirmationWithCheckbox } from '../../../components/modal-confirmation-with-checkbox/modal-confirmation-with-checkbox';
import { ApiAnnotationService } from '../../services/api/annotation/api-annotation.service';
import { mod } from '../../../shared/helpers/math';
import { TendersSearchService } from '../../services/tenders-search.service';

@Component({
  selector: 'app-tender-status-list',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    UpperCasePipe,
    NgTemplateOutlet,
    FormsModule,
    CommonExplainModule,
    NgClass,
    NgbTooltip,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    AutoselectDirective,
    CdkScrollable
  ],
  templateUrl: './tender-status-list.component.html',
  styleUrl: './tender-status-list.component.scss'
})
export class TenderStatusListComponent implements OnChanges, OnDestroy{
  MAX_STATUS_NAME_LENGTH = 20;
  STATUS_COUNT_LIMIT = 50;

  @Input() item?: DropdownItem<StatusItem>
  @Input() defaultItems: DropdownItem<StatusItem>[] = [];
  @Input() customItems: DropdownItem<StatusItem>[] = [];
  @Input() tendersSearchService?: TendersSearchService;
  @Input({transform: booleanAttribute}) editable = false;
  @Input({transform: booleanAttribute}) showNone = false;
  @Input({transform: booleanAttribute}) withCounter = false;

  @Output() itemSelect = new EventEmitter<DropdownItem<StatusItem> | null>();

  filterText = new FormControl("", { nonNullable: true });

  filteredItems: DropdownItem<StatusItem>[] = [];
  filteredDefaultItems: DropdownItem<StatusItem>[] = [];
  filteredCustomItems: DropdownItem<StatusItem>[] = [];

  collapseDefaultItems = false;
  collapseCustomItems = false;
  currentStatusEdition?: {
    id: number;
    name?: string;
  }
  currentSelectionIndex = 0;

  subscriptions: Subscription[] = []
  loading = false;

  @ViewChild("statusContainer") statusContainer?: ElementRef;

  constructor(private apiGroupAccountStatusService: ApiGroupAccountStatusService, private tendersModuleService: TendersModuleService,
              private modalService: NgbModal, private apiAnnotationService: ApiAnnotationService) {
    this.subscriptions.push(this.filterText.valueChanges.subscribe(() => {
      this.collapseDefaultItems = false;
      this.collapseCustomItems = false;
      this.updateFilteredItems();
      this.currentSelectionIndex = 0;
    }))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["item"] || changes["customItems"] || changes["defaultItems"]) {
      this.updateFilteredItems();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    })
  }

  updateFilteredItems() {
    this.filteredDefaultItems = this.defaultItems.filter((item) =>
      SearchHelper.searchRegExp(item.value.displayName || item.label, this.filterText.value)
    );
    this.filteredCustomItems = this.customItems.filter((item) =>
      SearchHelper.searchRegExp(item.value.displayName || item.label, this.filterText.value)
    );
    this.filteredItems = this.filteredDefaultItems.concat(this.filteredCustomItems);
  }

  computeColor(targetItem: DropdownItem<StatusItem>) {
    return `color-mix(in srgb, ${targetItem.value.color || 'transparent'} 50%, transparent)`;
  }

  nameExists(target?: string) {
    return !!this.defaultItems.concat(this.customItems).find((item) => {
      const formattedTarget = RegexpSearch.normalizeString(RegexpSearch.trim(target ?? this.filterText.value))
      const formattedItemName = RegexpSearch.normalizeString(RegexpSearch.trim(item.value.displayName || item.label))
      return formattedTarget === formattedItemName;
    });
  }

  async createStatus() {
    const statusName = RegexpSearch.trim(this.filterText.value)
    if (statusName.length > this.MAX_STATUS_NAME_LENGTH || this.customItems.length > this.STATUS_COUNT_LIMIT ||
      this.nameExists() || this.loading || !this.editable) return;
    this.loading = true;
    try {
      const { group_account_status_id: id } = await this.apiGroupAccountStatusService
        .createGroupAccountStatus({ displayedStatusName: statusName })
      await this.tendersModuleService.updateStatusItems();
      const status = this.tendersModuleService.statusItems.find((item) => item.value.id === id);
      if (status) this.itemSelect.emit(status);
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  renameInputChange(value: string) {
    if (this.currentStatusEdition && value.length <= this.MAX_STATUS_NAME_LENGTH)
      this.currentStatusEdition.name = RegexpSearch.trim(value);
  }

  async renameStatus(){
    if (this.currentStatusEdition?.name) {
      const statusName = RegexpSearch.trim(this.currentStatusEdition.name)
      const currentStatus = this.customItems.find((item) => item.value.id === this.currentStatusEdition?.id);
      if (!currentStatus || currentStatus?.label === statusName ||
        (currentStatus?.label.toLowerCase() !== statusName.toLowerCase() && this.nameExists(statusName))) return;
      this.loading = true;
      try {
        await this.apiGroupAccountStatusService.updateGroupAccountStatus({id: this.currentStatusEdition.id, displayedStatusName: statusName})
      } catch (error) {
        console.error(error);
        return;
      }
      this.loading = false;
      currentStatus.value.displayName = statusName;
      currentStatus.label = statusName;
    }
  }

  async closeStatusEdition() {
    await this.renameStatus();
    this.currentStatusEdition = undefined;
  }

  handleOutsideClick(event: MouseEvent, id: number) {
    if (this.currentStatusEdition?.id === id) {
      event.stopPropagation();
      this.closeStatusEdition();
    }
  }

  async deleteStatus() {
    if (this.currentStatusEdition?.id) {
      const id = this.currentStatusEdition.id;
      const modal = this.modalService.open(
        ModalConfirmationWithCheckbox, {centered: true, windowClass: 'modal-width-560'}
      );
      modal.componentInstance.deleteMode = true;
      modal.componentInstance.translationFileSection = 'tenders.status';
      modal.componentInstance.onValidateAction = async () => {
        await this.apiGroupAccountStatusService.deleteGroupAccountStatus(id);
        await this.tendersModuleService.updateStatusItems()
      };
      modal.componentInstance.affectedItemsCount = await this.apiAnnotationService.getAnnotationsCount({
        statusId: id,
      });
      this.currentStatusEdition = undefined;
    }
  }

  validateSelection() {
    if (this.currentSelectionIndex < this.filteredItems.length) this.itemSelect.emit(this.filteredItems[this.currentSelectionIndex]);
    else this.createStatus();
    this.currentSelectionIndex = 0;
  }

  copySelection() {
    if (this.currentSelectionIndex < this.filteredItems.length)
      this.filterText.setValue(this.filteredItems[this.currentSelectionIndex].label)
    this.currentSelectionIndex = 0;
  }

  moveSelection(direction: 'up' | 'down') {
    this.collapseDefaultItems = false;
    this.collapseCustomItems = false;
    if (direction === 'up')
      this.currentSelectionIndex = mod(this.currentSelectionIndex - 1, this.filteredItems.length + +!!this.filterText.value)
    else this.currentSelectionIndex = mod(this.currentSelectionIndex + 1, this.filteredItems.length + +!!this.filterText.value)
    this.statusContainer?.nativeElement.querySelectorAll(".status-item")[this.currentSelectionIndex].scrollIntoView({block: 'nearest'});
  }

  getCountOf(statusId: number) {
    return this.tendersSearchService?.statusCounts?.find((s) => s.statusId === statusId)?.count ?? 0;
  }
}
