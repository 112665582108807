import { TreeviewHelper } from '../helpers/treeview-helper';
import { TerritoryKind } from '../../../core/territory-kind/territory-kind.enum';

export interface TreeviewSelection {
  checkedItems: TreeviewItem[];
  uncheckedItems: TreeviewItem[];
}

const ALLOW_KIND = [TerritoryKind.COMMUNE, TerritoryKind.EPCI, TerritoryKind.DEPARTMENT];

export interface TreeItem {
  text: string;
  value: any;
  infos?: any;
  isRoot?: boolean;
  disabled?: boolean;
  checked?: boolean | null;
  outside_department_count?: any;
  collapsed?: boolean;
  children?: TreeItem[];
}

export class TreeviewItem {
  private internalDisabled = false;
  private internalChecked ? = true;
  private internalCollapsed = false;
  private internalIsRoot = false;
  private _children!: TreeviewItem[];
  text: string;
  value: any;
  infos: any;
  outside_department_count: any;


  constructor(item: TreeItem, autoCorrectChecked = false) {
    if (!item) {
      throw new Error('Item must be defined');
    }
    if (typeof item.text === 'string') {
      this.text = item.text;
    } else {
      throw new Error('A text of item must be string object');
    }
    this.value = item.value;
    this.infos = item.infos;
    this.outside_department_count = item.outside_department_count;
    if (typeof item.checked === 'boolean') {
      this.checked = item.checked;
    }
    if (typeof item.isRoot === 'boolean') {
      this.isRoot = item.isRoot;
    }
    if (typeof item.collapsed === 'boolean') {
      this.collapsed = item.collapsed;
    }
    if (typeof item.disabled === 'boolean') {
      this.disabled = item.disabled;
    }
    if (item.children && item.children.length > 0) {
      this.children = item.children.map(child => {
        if (this.disabled) {
          child.disabled = true;
        }

        return new TreeviewItem(child);
      });
    }

    if (autoCorrectChecked) {
      this.correctChecked();
    }
  }

  get checked(): boolean | undefined {
    return this.internalChecked;
  }

  set checked(value: boolean | undefined) {
    if (!this.internalDisabled) {
      if (this.internalChecked !== value) {
        this.internalChecked = value;
      }
    }
  }

  get indeterminate(): boolean {
    return this.checked === undefined;
  }

  setCheckedRecursive(value: boolean | undefined,
                      predicate?: (item: TreeviewItem) => boolean, selection?: TreeviewSelection): boolean | undefined {
    if (!this.internalDisabled) {
      let checkState: boolean | null | undefined = null;
      if (this._children) {
        this._children.forEach(child => {
          const childState = child.setCheckedRecursive(value, predicate, selection);
          if (checkState !== undefined) {
            if (checkState !== null && childState != checkState) {
              checkState = undefined;
            } else {
              checkState = childState;
            }
          }
        });
      }
      if (checkState === null) {
        if (predicate) {
          checkState = predicate(this) ? value : !value;
        } else if (selection) {
          const wasChecked = selection.checkedItems.some((item) => item.value === this.value);
          checkState = wasChecked || selection.uncheckedItems.some((item) => item.value === this.value) ?
            wasChecked : value;
        } else {
          checkState = value;
        }
      }
      this.internalChecked = checkState;
      return checkState;
    }
    return;
  }

  get disabled(): boolean {
    return this.internalDisabled;
  }

  set disabled(value: boolean) {
    if (this.internalDisabled !== value) {
      this.internalDisabled = value;
      if (this._children) {
        this._children.forEach(child => child.disabled = value);
      }
    }
  }

  setDisabledRecursive(value: boolean, predicate?: (item: TreeviewItem) => boolean) {
    if (this.internalDisabled !== value) {
      let disabledState;
      if (predicate) {
        disabledState = predicate(this) ? value : !value;
      } else disabledState = value;
      this.internalDisabled = disabledState;
      if (this._children) {
        this._children.forEach(child => child.setDisabledRecursive(value, predicate));
      }
    }
  }

  get collapsed(): boolean {
    return this.internalCollapsed;
  }

  set collapsed(value: boolean) {
    if (this.internalCollapsed !== value) {
      this.internalCollapsed = value;
    }
  }

  get isRoot(): boolean {
    return this.internalIsRoot;
  }

  set isRoot(value: boolean) {
    if (this.internalIsRoot !== value) {
      this.internalIsRoot = value;
    }
  }

  setCollapsedRecursive(value: boolean, genericMethod = false): void {
    if (genericMethod && !this.isRoot) {
      this.internalCollapsed = value;
    } else if (this.infos?.kind === TerritoryKind.EPCI && !this.isRoot) {
      this.internalCollapsed = value;
    }
    if (this._children) {
      this._children.forEach(child => child.setCollapsedRecursive(value));
    }
  }


  setSearchCountRecursive(searchCounts: any): void {
    this.infos['searchCount'] = searchCounts[this.value];
    if (this._children) {
      this._children.forEach(child => child.setSearchCountRecursive(searchCounts));
    }
  }

  get children(): TreeviewItem[] {
    return this._children;
  }

  set children(value: TreeviewItem[]) {
    if (this._children !== value) {
      if (value && value.length === 0) {
        throw new Error('Children must be not an empty array');
      }
      this._children = value;
      if (this._children) {
        const checkedCount = this._children.reduce((total, child) => {
          return total + (child.checked === undefined ? 1 : +child.checked)
        }, 0);
        if (checkedCount === 0) {
          this.internalChecked = false;
        } else if (checkedCount === this._children.length) {
          this.internalChecked = true;
        } else {
          this.internalChecked = undefined;
        }
      }
    }
  }

  getSelection(): TreeviewSelection {
    let checkedItems: TreeviewItem[] = [];
    let uncheckedItems: TreeviewItem[] = [];
    if (this.infos && ALLOW_KIND.includes(this.infos['kind'])) {
      if (this.internalChecked || this.indeterminate) {
        checkedItems.push(this);
      } else {
        uncheckedItems.push(this);
      }
    } else {
      if (this.value) {
        if (this.internalChecked) {
          checkedItems.push(this);
        } else {
          uncheckedItems.push(this);
        }
      }
    }

    if (this._children) {
      const selection: {
        checked: TreeviewItem[],
        unchecked: TreeviewItem[]
      } = TreeviewHelper.concatSelection(this._children, checkedItems, uncheckedItems);
      checkedItems = selection.checked;
      uncheckedItems = selection.unchecked;
    }

    return {
      checkedItems,
      uncheckedItems
    };
  }

  correctChecked(): void {
    this.internalChecked = this.getCorrectChecked();
  }

  private getCorrectChecked(): boolean | undefined {
    let checked: boolean | undefined;
    if (this._children) {
      for (const child of this._children) {
        child.internalChecked = child.getCorrectChecked();
        if (checked === null) {
          checked = child.internalChecked;
        } else if (checked !== child.internalChecked) {
          checked = undefined;
          break;
        }
      }
    } else {
      checked = this.checked;
    }

    return checked;
  }
}

export const noResultTreeviewItem = new TreeviewItem({
  text: 'Aucun résultat',
  value: 'Aucun résultat',
  disabled: true,
  children: []
});

export function getAllChildren(array: TreeviewItem[]): TreeviewItem[] {
  const allChildren: TreeviewItem[] = [];
  array?.forEach((item) => {
    allChildren.push(item);
    if (item.children) {
      getAllChildren(item.children).forEach((child: TreeviewItem) => {
        allChildren.push(child);
      });
    }
  });
  return allChildren;
}


export function flattenTreeview(treeviewList: TreeviewItem[]): TreeviewItem[] {
  return treeviewList.reduce((res: TreeviewItem[], treeview) => {
    if (treeview.children) {
      return [...res, ...flattenTreeview(treeview.children)];
    } else {
      return [...res, treeview];
    }
  }, []);
}
