<ex-dropdown-v2>

  <ng-template exDropdownV2Header let-value let-toggle="toggle" let-open="open">
    <input #dateInput
           type="date"
           [value]="inputDateString"
           (input)="onInputDateStringChange(dateInput.value)"
           (click)="$event.preventDefault(); toggle(true)"
           (focus)="$event.preventDefault(); toggle(true); inputFocus = true"
           (blur)="checkAndAdjustInputDateString(dateInput.value, true); inputFocus = false; onSubmit()"
           (keydown.space)="$event.preventDefault()"
           (keydown.enter)="toggle(false); dateInput.blur()"
           (keydown.tab)="inputFocus = false; toggle(false); (tip === 'to') ? onSubmit() : ''"
           (keydown.shift.tab)="inputFocus = false; toggle(false); (tip === 'from') ? onSubmit() : ''"
           (keydown.backspace)="$event.preventDefault()"
           (keydown.shift.backspace)="$event.preventDefault()"
           (keydown.control.backspace)="$event.preventDefault()"
           (keydown.alt.backspace)="$event.preventDefault()"
           (keydown.meta.backspace)="$event.preventDefault()"
           (keydown.arrowDown)="inputFocus = true; toggle(true)"
           (keydown.arrowLeft)="inputFocus = true; toggle(true)"
           (keydown.arrowRight)="inputFocus = true; toggle(true)"
           (keydown.arrowUp)="inputFocus = true; toggle(true)"
           [ngClass]="{'invalid': isInvalid() || periodNotValid, 'is-firefox': browser.isFirefox}"
           [min]="getDateStringFromNgbDate(allowedDateRange?.min ?? today)"
           [max]="getDateStringFromNgbDate(allowedDateRange?.max ?? today)"
           required
    />
  </ng-template>

  <ng-template exDropdownV2Content let-value let-toggle="toggle">
    <div class="dropdown-content-container">
      <div class="d-flex pdb-8px select-date-text">
        {{ explanations }}
      </div>
      <ngb-datepicker #customDatePicker
                      class="no-select"
                      (dateSelect)="onModelChange($event); toggle(false)"
                      [ngModel]="selectedNgbDate"
                      (navigate)="navigate($event)"
                      [displayMonths]="1"
                      [dayTemplate]="dayTemplate"
                      [minDate]="allowedDateRange?.min ?? today"
                      [maxDate]="allowedDateRange?.max ?? today"
                      [startDate]="ngbMonthYear"
                      outsideDays="collapsed"/>
    </div>
  </ng-template>

</ex-dropdown-v2>

<ng-template #dayTemplate let-date let-focused="focused" let-disabled="disabled">
    <span
      class="custom-day no-select"
      [class.focused]="isFocused(date)"
      [class.disabled]="disabled"
      [class.faded]="hoveredDate"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
</ng-template>

