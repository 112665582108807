import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrowserDetectorService {

  constructor() { }

  get isChromeBased() {
    // @ts-ignore
    return (/Chrome/.test(navigator.userAgent) && !!window.chrome && 'webkitRequestAnimationFrame' in window);
  }

  get isFirefox() {
    // @ts-ignore
    return /Firefox/.test(navigator.userAgent) && typeof InstallTrigger !== 'undefined';
  }
}
