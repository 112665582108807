
export class RegexpSearch {
    public static normalizedSearch (str1: string, str2: string): boolean {
        str1 = str1.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase() ;
        str2 = str2.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase() ;
        return str1.includes(str2) ;
    }

    public static normalizedSearchWithAccents(str1: string, str2: string): boolean {
        return this.normalizeString(str1).includes(this.normalizeString(str2));
    }

    public static normalizeString(str: string): string {
        return str
          .normalize('NFKD')                 // Decompose characters (compatibility)
          .replace(/\p{Diacritic}/gu, "")    // Remove diacritics
          .toLowerCase()
          .replace(/œ/g, "oe")               // Replace ligature œ → oe
          .replace(/æ/g, "ae")               // Replace ligature æ → ae
          .replace(/ß/g, "ss")               // Replace sharp S (ß) → ss
          .replace(/ø/g, "o")                // Replace slashed o (ø) → o
          .replace(/đ/g, "d")                // Replace crossed d (đ) → d
          .replace(/ł/g, "l")                // Replace stroked l (ł) → l
          .replace(/[\u2000-\u206F]/g, "");  // Remove special Unicode punctuation
    }

    public static trim(str: string): string {
      return str.trim().replace(/\s\s+/g, ' ');
    }
}


