<div class="d-flex h-100 gap-4px">
  <div #firstDateSelector>
    <ex-date-selector tip="from"
                      class="d-flex pdl-4px"
                      (dateSelect)="onDateSelection($event, 'from')"
                      [inputPeriod]="dbPeriod"
                      [inputDate]="defaultPeriod?.from ?? today"
                      [allowedDateRange]="allowedDateRange"
                      [periodNotValid]="periodNotValid"
                      (keydown.tab)="onTabKeyFirstSelector($event)"
                      [explanations]="'tenders.date-selector.' + periodField | translate"
    />
  </div>
  <div class="separator">
    <div class="separator-dot"></div>
  </div>
  <div #secondDateSelector>
    <ex-date-selector tip="to" class="d-flex"
                      (dateSelect)="onDateSelection($event, 'to')"
                      [inputPeriod]="dbPeriod"
                      [inputDate]="defaultPeriod?.to ?? today"
                      [allowedDateRange]="allowedDateRange"
                      [periodNotValid]="periodNotValid"
                      (keydown.tab)="onTabKeySecondSelector($event)"
                      [explanations]="'tenders.date-selector.' + periodField | translate"
    />
  </div>
</div>
