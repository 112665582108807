import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'relevance-indicator',
  templateUrl: './relevance-indicator.component.html',
  styleUrls: ['./relevance-indicator.component.scss'],
  imports: [
    MatIcon,
    NgbTooltip,
    TranslateModule
  ],
  standalone: true
})
export class RelevanceIndicatorComponent {

}
