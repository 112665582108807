<div class="modal-share-container">
  <div class="modal-body w-100">

    <!--LEFT PART : editeur de texte et sélecteurs -->
    <div class="left-part">
      <div class="flex-grow-1">
        <h4 class="modal-title">
          <i *ngIf="emailShare" class="ph ph-arrow-left text-basic fs-4 me-2" (click)="emailShare = false"></i>
          {{ 'tenders.market-watches.modal-share-watch.title' | translate }}
        </h4>
      </div>
      <div class="d-flex w-100 flex-column">
        <div class="checkbox-container pdl-16px">
          <!--checkbox - Note-->
          <div [ngbTooltip]="!note ? 'Pas de note associée à ce marché.' : null"
               tooltipClass="tooltip-share-watch"
               [openDelay]="500">
            <ng-container [ngTemplateOutlet]="checkboxTemplate"
                          [ngTemplateOutletContext]="{$implicit: 'note',
                                                        disabledValue: !note}"/>
          </div>
          <!--checkbox - Summary-->
          <div
            [ngbTooltip]="(areChunksDeindexed || !tender.dceProjectUid) ? 'DCE indisponible, ou marché trop ancien.' : null"
            tooltipClass="tooltip-share-watch"
            [openDelay]="500">
            <ng-container [ngTemplateOutlet]="checkboxTemplate"
                          [ngTemplateOutletContext]="{$implicit: 'summary',
                                                      disabledValue: (areChunksDeindexed || !tender.dceProjectUid)}"/>
          </div>
          <!--checkbox - pinned questions-->
          <div [ngbTooltip]="(areChunksDeindexed || !tender.dceProjectUid) ? 'DCE indisponible, ou marché trop ancien.' :
            (smartGridService.pinnedQuestions.length === 0) ? 'Pas de question épinglée associée à ce marché.' : null"
               tooltipClass="tooltip-share-watch"
               [openDelay]="500">
            <ng-container [ngTemplateOutlet]="checkboxTemplate"
                          [ngTemplateOutletContext]="{$implicit: 'pinned_questions',
                                                      disabledValue: (areChunksDeindexed || !tender.dceProjectUid) || smartGridService.pinnedQuestions.length === 0 }"
                          [ngbTooltip]="'Pas de note associée à ce marché.'"/>
          </div>
        </div>

        <div class="w-100 d-flex">
          <div class="loader-container" [hidden]="!showLoader">
            <app-simple-loader [vertical]="true"></app-simple-loader>
          </div>
          <div [hidden]="showLoader" id="contentToShare" class="metadata-box w-100"
               [ngClass]="{'max-length-error': maxLengthReached}">
            <quill-editor id="modalShareQuillEditor"
                          class="w-100 bdr-4px"
                          [(ngModel)]="content"
                          [modules]="modules"
                          (onContentChanged)="onContentChanged($event)"
                          (onEditorCreated)="onEditorCreated($event)"
                          [maxlength]="MAX_LENGTH"
            />
          </div>
        </div>
      </div>
    </div>

    <mat-divider vertical class="ignore-parent-padding color-basic-500"></mat-divider>

    <!--RIGHT PART : editeur de texte et sélecteurs -->
    <div class="right-part pdl-16px pdr-16px d-flex flex-column">
      <div class="header d-flex justify-content-between">
        <h4 class="modal-title">
          <i *ngIf="emailShare" class="ph ph-arrow-left text-basic fs-4 me-2" (click)="emailShare = false"></i>
          {{ 'tenders.market-watches.modal-share-watch.options-title' | translate }}
        </h4>
        <i class="ph-bold ph-x"
           (click)="cancel()"></i>
      </div>
      <app-sharing-panel [module]="ExplainModuleEnum.TENDERS"
                         [inputHtml]="htmlContent"
                         [disableShareButton]="showLoader"
                         [document]="document" (cancel)="emailShare = false" (share)="cancel()"
      />

      <mat-divider class="color-basic-500"></mat-divider>

      <span class="sub-title pdb-16px">{{ 'common.copy-to-clipboard' | translate | uppercase }}</span>
      <button type="button" class="btn share-action-btn d-flex"
              [disabled]="showLoader"
              (click)="onCopy()">
        <i class="ph-fill ph-copy fs-5"></i>
        <span class="align-self-center">{{ 'common.copy' | translate }}</span>
      </button>
    </div>

  </div>


  <ng-template #checkboxTemplate let-attribute let-disabledValue="disabledValue">
    <div class="checkbox">
      <label class="container d-flex align-items-center w-100">
        <div class="d-flex"
             [ngClass]="{'disabled': disabledValue}">
          <input type="checkbox" class="form-check-input"
                 (click)="toggleCheckbox(attribute, $event)"
                 [checked]="displays[attribute] && !disabledValue"
                 [disabled]="disabledValue || showLoader"
          >
          <span class="checkmark"></span>
          <span
            class="checkbox-label">{{ 'tenders.market-watches.modal-share-watch.display-' + attribute | translate }}</span>
        </div>
      </label>
    </div>
  </ng-template>

</div>
