import { Component, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'app-modal-confirmation-simple',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './modal-confirmation-simple.component.html',
  styleUrl: './modal-confirmation-simple.component.scss'
})
export class ModalConfirmationSimple {
  @Input() title!: string;
  @Input() content!: string;
  @Input() validate?: string;
  @Input() cancel = 'Annuler';
  @Input() onValidateAction!: () => void | Promise<void>;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  confirm() {
    this.activeModal.close(true);
    this.onValidateAction();
  }

}
