<div class="container">
  <div class="smartgrid-container">
    <div class="loader-container"
         *ngIf="(!smartGridService.pinnedQuestionsReady || !smartGridService.unpinnedQuestionsReady) && this.tenderDceProjectUid">
      <app-simple-loader
        [loaderText]="'tenders.smart-grid.grid-loader' | translate"
        [vertical]="true">
      </app-simple-loader>
    </div>
    <div
      *ngIf="(smartGridService.pinnedQuestionsReady && smartGridService.unpinnedQuestionsReady) || !this.tenderDceProjectUid"
      class="not-available">

      <ex-empty-state *ngIf="!tenderDceProjectUid"
                      [svgIcon]="'close-circle-fill'"
                      [title]="'tenders.smart-grid.grid-not-available-title' | translate">
        <div *exEmptyStateContent class="not-available-text">
          <span>{{'tenders.smart-grid.grid-not-available-no-dce' | translate}}</span>
        </div>
      </ex-empty-state>
      <ex-empty-state *ngIf="tenderDceProjectUid && smartGridService.checkIfTenderOpenedAfterLimitDate()"
                      [svgIcon]="'close-circle-fill'"
                      [title]="'tenders.smart-grid.grid-not-available-title' | translate">
        <div *exEmptyStateContent class="not-available-text">
          <span>{{'tenders.smart-grid.grid-not-available-expired-market' | translate}}</span>
        </div>
      </ex-empty-state>
    </div>

    <div
      *ngIf="(smartGridService.pinnedQuestionsReady && smartGridService.unpinnedQuestionsReady) && tenderDceProjectUid && !smartGridService.checkIfTenderOpenedAfterLimitDate()">
      <div *ngIf="smartGridService.pinnedQuestions?.length === 0 && smartGridService.unpinnedQuestions?.length=== 0"
           class="not-available">
        <div class="not-available-title">
          <span>{{'tenders.smart-grid.grid-not-available-title' | translate}}</span>
        </div>
        <div class="not-available-text">
          <span>{{'tenders.smart-grid.grid-not-available-failed' | translate}}</span>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="summaryQuestionTemplate;
            context: {question: smartGridService.summaryQuestion}">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="pinnedQuestionsTemplate;
        context: {questions: smartGridService.pinnedQuestions}">
      </ng-container>

      <ng-container
        *ngTemplateOutlet="unpinnedQuestionsTemplate;
        context: {questions: smartGridService.unpinnedQuestions}">
      </ng-container>
    </div>
  </div>
</div>
<ng-template #tooltipCopy>
  <span>{{'common.copy-to-clipboard' | translate}}</span>
</ng-template>
<ng-template #tooltipCopied>
  <span>{{'common.copied-to-clipboard' | translate}}</span>
</ng-template>
<ng-template #tooltipEditDelete>
  <span>{{'common.edit-delete' | translate}}</span>
</ng-template>
<ng-template #tooltipCannotEditDelete let-questionOwner=questionOwner let-questionType=questionType>
  <span>{{'tenders.smart-grid.no-editable-question' | translate }}</span>
  <span>{{questionOwner ? ('tenders.smart-grid.question-created-by' | translate :{owner: questionOwner}) : '' }}</span>
  <span>{{(!questionOwner && questionType == GridQuestionTypeEnum.PUBLIC) ? ('tenders.smart-grid.default-question' | translate) : ''}}</span>
</ng-template>
<ng-template #tooltipPin>
  <span>{{'common.pin' | translate}}</span>
</ng-template>
<ng-template #tooltipUnpin>
  <span>{{'common.unpin' | translate}}</span>
</ng-template>

<ng-template #tooltipMaxPinnedQuestions>
  <span>{{'tenders.smart-grid.max-pinned-questions' | translate}}</span>
</ng-template>

<ng-template #tooltipOpenQuestionInfo>
  <span>{{'common.click-to-display' | translate}}</span>
</ng-template>

<ng-template #popupMoreInfo>
  <div class="more-info">
    <i class="ph ph-x" (click)="$event.stopPropagation();"></i>
    <div class="question-info">
      <div><strong>{{'common.created-by' | translate}} : </strong>
        {{ questionWithInfoOpened?.ownerFirstname ? questionWithInfoOpened?.ownerFirstname + ' ' + questionWithInfoOpened?.ownerLastname : ''}}
        {{ questionWithInfoOpened?.type === GridQuestionTypeEnum.PUBLIC ? ('tenders.smart-grid.default-question-owner' | translate) : '' }}
      </div>
      <div><strong>{{'tenders.smart-grid.question' | translate}} : </strong> {{questionWithInfoOpened?.name}}
        <i
          *ngIf="!isCopied"
          class="ph-fill ph-copy copy-to-clipboard"
          (click)="copyNameToClipboard($event, questionWithInfoOpened?.name || '')"
          [ngbTooltip]="tooltipCopy"
          tooltipClass="custom-tooltip-smart-grid-cards"
          placement="bottom"
        ></i>
        <i
          *ngIf="isCopied"
          class="ph-fill ph-copy copy-to-clipboard copied"
          (click)="copyNameToClipboard($event, questionWithInfoOpened?.name || '')"
          [ngbTooltip]="tooltipCopied"
          tooltipClass="custom-tooltip-smart-grid-cards"
          placement="bottom"
        ></i>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #QuestionActions let-question="question" let-type='type'>
  <div class="question-name" [id]="question.questionId">
    <div class="name">
      {{  question.displayedName}}

      <i class="ph ph-info"
         [ngbPopover]="popupMoreInfo"
         #popover="ngbPopover"
         popoverClass="custom-popover"
         placement="end"
         (click)="$event.stopPropagation();onClickOpenMoreInfo(question, type)"
         [ngbTooltip]="tooltipOpenQuestionInfo"
         tooltipClass="custom-tooltip-smart-grid-cards max-width"
         placement="top"
         [disableTooltip]="popover.isOpen()"
      >
      </i>
    </div>


    <div *ngIf="!question.answer && question.isGenerationFinished  &&
        (clickedQuestion?.questionId === question.questionId  || type === GridQuestionSectionEnum.PINNED)"
         class="text not-available-answer-text">
      {{ question.isGenerationFailed ? ('tenders.smart-grid.card-not-available-failed' | translate) :
      'tenders.smart-grid.card-not-available-expired-market' | translate }}
    </div>

  </div>

  <div class="question-actions">

    <ex-button icon="pencil-simple-fill"
               size="small"
               type="secondary"
               [ngbTooltip]="checkIfOwnerOfQuestion(question) ? tooltipEditDelete : tooltipCannotEditDelete"
               tooltipClass="custom-tooltip-smart-grid-cards"
               [tooltipContext]="{
                    questionOwner: question.ownerFirstname ?(question.ownerFirstname + ' ' + question.ownerLastname): null,
                     questionType: question.type
               }"
               placement="top"
               [ngClass]="{'disabled': !checkIfOwnerOfQuestion(question)}"
               (click)="checkIfOwnerOfQuestion(question) && editQuestion(question)">
    </ex-button>

    <div class="card-selected-icons-container" *ngIf="question.answer">
      <ex-button
        *ngIf="!isCopied"
        icon="copy-fill"
        size="small"
        type="secondary"
        class="copy-to-clipboard"
        (click)="copyToClipboard($event, question)"
        [ngbTooltip]="tooltipCopy"
        tooltipClass="custom-tooltip-smart-grid-cards"
        placement="bottom"
      ></ex-button>
      <ex-button
        *ngIf="isCopied"
        icon="copy-fill"
        size="small"
        type="secondary"
        class="copy-to-clipboard copied"
        (click)="copyToClipboard($event, question)"
        [ngbTooltip]="tooltipCopied"
        tooltipClass="custom-tooltip-smart-grid-cards"
        placement="bottom"
      ></ex-button>
    </div>
  </div>
</ng-template>


<ng-template #summaryQuestionTemplate let-question="question">
  <div>
    <div class="header">
      <div class="header-title">
        <span>
          {{ 'tenders.smart-grid.section.summary' | translate | uppercase }}
        </span>
      </div>
    </div>
    <div class="cards-container ps-3">
      <div class="card-item cctp-question">
        <div class="question-card-content">
          <div class="text no-summary-answer"
               *ngIf="!question || (!question.answer &&  question.isGenerationFinished)">
            {{'tenders.smart-grid.no-cctp-answer' | translate}}
          </div>
          <div *ngIf="question && (question.answer || !question.isGenerationFinished)"
               class="question-card-body cctp-card-body"
          >
            <div class="loaders" *ngIf="!question.isGenerationFinished">
              <ngx-skeleton-loader/>
              <ngx-skeleton-loader/>
              <ngx-skeleton-loader/>
            </div>
            <div *ngIf="question.isGenerationFinished && question.answer"
                 class="answer-container">
              <div>

                <div class="text full-answer">
                  <div *ngIf="question.answer.textWithRefs">
                    <ng-container *ngFor="let item of question.answer.textWithRefs">{{item.text}}<a
                      *ngIf="item.ref" class="answer-ref"
                      (click)="onClickRef(item, question)"
                      [ngClass]="{ 'active': clickedRefIndex === item.index }">{{item.index + 1}}</a>
                    </ng-container>
                  </div>
                  <div *ngIf="!question.answer.textWithRefs.length">
                    {{question.answer.text}}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pinnedQuestionsTemplate let-questions="questions">
  <div>
    <div class="header">
      <div class="header-title">
        <span>
              {{ 'tenders.smart-grid.section.pinned' | translate | uppercase }}
        </span>
      </div>
    </div>
    <div class="cards-container" [ngClass]="!questions.length ? 'ps-3' : ''">
      <div class="no-pinned-questions" *ngIf=" !questions.length">
        <div class="icons">
          <i class="ph-fill ph-push-pin"></i>
          <i class="ph-fill ph-cursor-click"></i>
        </div>
        <div class="content">
          <p class="title"> {{'tenders.smart-grid.no-pinned-questions.title' | translate}}</p>
          <p class="description">{{'tenders.smart-grid.no-pinned-questions.description' | translate}}</p>
        </div>
      </div>
      <div *ngFor="let question of questions; let i= index; trackBy: trackByQuestionId"

           class="card-item"
           [ngClass]="{
                    'active': checkIfQuestionIsSelected(question),
                    'disabled':question.isGenerationFinished && !question.answer
                 }"
           (click)="question.answer ? onClickQuestion(question) : ''"
           (mouseenter)="fetchQuestionInfo(question, GridQuestionSectionEnum.PINNED)"

      >
        <i class="ph-fill ph-push-pin pin-unpin-icon"
           [ngClass]="{
               'ph-push-pin-slash': questionHovered === question.questionId,
             }"
           (mouseenter)="questionHovered = question.questionId"
           (mouseleave)="questionHovered = null"
           (click)="$event.stopPropagation(); unpinQuestion(question)"
           [ngbTooltip]="tooltipUnpin"
           placement="bottom"
        ></i>
        <div class="question-card-content">
          <div class="question-card-header">
            <div [ngClass]="{'not-available-answer': question.isGenerationFinished && !question.answer}"
                 class="question-card-title">
              <ng-container
                *ngTemplateOutlet="QuestionActions;
                       context: {question: question, type : GridQuestionSectionEnum.PINNED}">
              </ng-container>
            </div>
          </div>

          <div *ngIf="question.answer || !question.isGenerationFinished" class="question-card-body">
            <div class="loaders" *ngIf="!question.isGenerationFinished">
              <ngx-skeleton-loader/>
            </div>
            <div *ngIf="question.isGenerationFinished && question.answer" class="answer-container">
              <div>
                <div class="text"
                     [ngClass]="{
                                 'cropped-answer': !checkIfQuestionIsSelected(question) ,
                                 'full-answer': checkIfQuestionIsSelected(question)
                               }">
                  <div *ngIf="question.answer.textWithRefs">
                    <ng-container *ngFor="let item of question.answer.textWithRefs">{{item.text}}<a
                      *ngIf="item.ref" class="answer-ref"
                      (click)="onClickRef(item, question)"
                      [ngClass]="{ 'active': clickedRefIndex === item.index }">{{item.index + 1}}</a>
                    </ng-container>
                  </div>
                  <div *ngIf="!question.answer?.textWithRefs.length">
                    {{question.answer?.text}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #unpinnedQuestionsTemplate let-questions="questions">
  <div>
    <div class="header">
      <div class="header-title">
        <div class="unpinned-questions-header">
          <span>{{ 'tenders.smart-grid.section.unpinned' | translate | uppercase }}</span>
          <button class="add-question-button"
                  [ngClass]="{'disabled': smartGridService.getSumOfPrivateQuestions() >= smartGridService.MAX_PRIVATE_QUESTIONS}"
                  *ngIf="tenderDceProjectUid"
                  (click)="onClickAddQuestion()"
                  [disabled]=" smartGridService.getSumOfPrivateQuestions() >= smartGridService.MAX_PRIVATE_QUESTIONS"
                  [ngbTooltip]=" smartGridService.getSumOfPrivateQuestions() >= smartGridService.MAX_PRIVATE_QUESTIONS
                ? ('tenders.smart-grid.max-questions' | translate) :'' "
                  tooltipClass="simple-tooltip"
                  placement="bottom auto" [openDelay]="500"
          >
            <mat-icon svgIcon="plus"></mat-icon>
            <span>{{'tenders.tender-detail.add' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="cards-container">
      <div *ngFor="let question of questions; let i= index; trackBy: trackByQuestionId"

           class="card-item"
           [ngClass]="{
                    'active': checkIfQuestionIsSelected(question),
                    'not-available-answer': question.isGenerationFinished && !question.answer && clickedQuestion?.questionId === question.questionId,
                 }"
           (click)="onClickQuestion(question)"
           (mouseenter)="fetchQuestionInfo(question, GridQuestionSectionEnum.UNPINNED)"
      >
        <i class="ph ph-push-pin pin-unpin-icon pin"
           (click)="$event.stopPropagation(); pinQuestion(question)"
           [ngClass]="{
               'disabled': smartGridService.pinnedQuestions.length >= smartGridService.MAX_PINNED_QUESTIONS,
               'ph-fill': questionHovered === question.questionId,
             }"
           (mouseenter)="questionHovered = question.questionId"
           (mouseleave)="questionHovered = null"
           [ngbTooltip]="smartGridService.pinnedQuestions.length >= smartGridService.MAX_PINNED_QUESTIONS ? tooltipMaxPinnedQuestions : tooltipPin"
           tooltipClass="custom-tooltip-smart-grid-cards max-pin-tooltip"
           placement="bottom"
        ></i>
        <div class="question-card-content">
          <div class="question-card-header">
            <div
              [ngClass]="{'not-available-answer': question.isGenerationFinished && !question.answer && clickedQuestion?.questionId === question.questionId}"
              class="question-card-title">
              <ng-container
                *ngTemplateOutlet="QuestionActions;
                       context: {question: question, type : GridQuestionSectionEnum.UNPINNED}">
              </ng-container>
            </div>
          </div>
          <div *ngIf="
                    question.questionId === clickedQuestion?.questionId
                    && !question.isGenerationFailed"
               class="question-card-body"
          >
            <div class="loaders" *ngIf="!question.isGenerationFinished">
              <ngx-skeleton-loader/>
            </div>
            <div *ngIf="question.isGenerationFinished && question.answer"
                 class="answer-container">
              <div>
                <div class="text"
                     [ngClass]="{
                                 'cropped-answer': !checkIfQuestionIsSelected(question),
                                 'full-answer': checkIfQuestionIsSelected(question)
                               }">
                  <div>{{question.answer.text}}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>
