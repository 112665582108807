import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HeadersService } from './headers/headers.service';
import { LoaderService } from '../../../common-explain/services/loader.service';
import { TerritoryCountService } from '../territory-count.service';
import { Observable } from 'rxjs';
import { AdminDocument, DbAdminDocument } from '../../../models/admin-document';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { SearchMode } from '../../../components/search/search-mode';
import { EventTypeName } from '../../../models/user-tracker';
import {TerritoryForAdminDoc} from "../../../models/territory/territory-utils";

@Injectable({
  providedIn: 'root'
})
export class ApiAdminDocService {
  adminDocExcerptSize = 750;

  constructor(
    private http: HttpClient,
    private headersService: HeadersService,
    private loaderService: LoaderService,
    private territoryCountService: TerritoryCountService
  ) {
  }

  getAdminDoc(query: string, params: HttpParams, trackingEventName: EventTypeName | ''): Observable<AdminDocument> {
  const options = this.headersService.getHttpOptionsWithParams(params, trackingEventName);
  return this.http.get<DbAdminDocument>(`${environment.explainApiUrl}admin_doc/${query}`, options)
    .pipe(map((response: DbAdminDocument) => {
      return new AdminDocument(response);
    }));
}

  getAdminDocCount(body?: object, params?: HttpParams): Observable<number> {
    return this.http.post(`${environment.explainApiUrl}admin_doc/count`, body, this.headersService.getHttpOptionsWithParams(params))
      .pipe(map(((res: any) => res['count'])));
  }
  /** Version of getAdminDoc for the guest part of the application */
  getSharedAdminDoc(query: string, shareToken: string): Observable<AdminDocument> {
    return this.http.get<DbAdminDocument>(`${environment.explainApiUrl}admin_doc/${query}`, this.headersService.getSharedHeaders(shareToken))
      .pipe(map((response: DbAdminDocument) => {
        return new AdminDocument(response);
      }));
  }

  retrieveAdminDocs(body: any, searchMode: SearchMode | undefined): Observable<object> {
    const hasLoader = true; // All time loader
    this.loaderService.displayLoader(hasLoader);
    body['excerpt_size'] = this.adminDocExcerptSize;

    // If the topics mode is engaged -> adapt the body before request.
    if (searchMode === SearchMode.TOPICS) {
      body['filters'] = {...body['filters'], 'topics': [Number(body['filters']['text'])]};
      body = {...body, 'service': 'search-topics'};
    }

    return this.http.post(`${environment.explainApiUrl}admin_doc/search`, body, this.headersService.httpHeaders)
      .pipe(map((entities: any) => {
        this.loaderService.hideLoader(hasLoader);

        this.territoryCountService.territoriesCount.next(entities.territory_count);
        entities.data.map((document: DbAdminDocument) => {
          document.territories = (document.territories as TerritoryForAdminDoc[]).filter(territory => territory.displayed)
        })
        return entities as {data: DbAdminDocument[], territory_count: any, total_count: number};
      }));
  }

  retrieveAdminDocsCount(body: object): Observable<object> {
    const hasLoader = true; // All time loader
    this.loaderService.displayLoader(hasLoader);
    return this.http.post(`${environment.explainApiUrl}admin_doc/count`, body, this.headersService.httpHeaders)
      .pipe(map(entities => {
        this.loaderService.hideLoader(hasLoader);
        return entities;
      }));
  }
}
