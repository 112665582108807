import { booleanAttribute, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SortDropdownItem } from '../../../common-explain/components/ex-sort-dropdown/ex-sort-dropdown.component';
import { DbPostSearchSort, SortFieldEnum } from '../../models/tender-search-body';
import { SortDirEnum } from '../../../common-explain/models/sort';
import { TendersSearchService } from '../../services/tenders-search.service';

const SORT_CONFIG = [
  {field: SortFieldEnum.RELEVANCE, dir: SortDirEnum.DESC, unidirectional: true},
  {field: SortFieldEnum.INDEXATION_DATE, dir: SortDirEnum.DESC},
  {field: SortFieldEnum.CLOSING_DATE, dir: SortDirEnum.ASC},
  {field: SortFieldEnum.ESTIMATED_END_DATE, dir: SortDirEnum.ASC},
];

@Component({
  selector: 'app-tenders-sort',
  templateUrl: './tenders-sort.component.html',
  styleUrls: ['./tenders-sort.component.scss']
})
export class TendersSortComponent implements OnInit, OnChanges {
  @Input({required: true}) tendersSearchService!: TendersSearchService;
  @Input() defaultSort?: '_score' | 'indexation_date' | 'closing_date' | 'estimated_end_date';
  @Input() sortFields?: SortFieldEnum[];
  @Input() size: string = 'md';
  sortItems!: SortDropdownItem[];
  sort?: DbPostSearchSort;

  @Input({transform: booleanAttribute}) inBoardView = false;

  ngOnInit() {
    this.sortItems = SORT_CONFIG
      .filter(({field}) => this.sortFields?.includes(field) ?? true)
      .map(({field, dir, unidirectional}) => ({
        label: `tenders.sort.${field}`,
        description: `tenders.sort.${field}_${dir}`,
        value: field,
        direction: dir,
        unidirectional,
        onSort: (item) =>
          item.description = `tenders.sort.${item.value}_${item.direction}`
      }));
    this.sort = this.defaultSort ?
      SORT_CONFIG.find(({field}) => field === (this.defaultSort ?? SortFieldEnum.RELEVANCE)) :
      this.tendersSearchService.sort
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tendersSearchService']) {
      this.sort = this.tendersSearchService.sort ??
        SORT_CONFIG.find(({field}) => field === (this.defaultSort ?? SortFieldEnum.RELEVANCE));
    }
  }

  onSort(value: DbPostSearchSort) {
    this.tendersSearchService.sort = value;
    this.sort = value;
  }

  protected readonly SortFieldEnum = SortFieldEnum;
}
