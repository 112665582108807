import {
  AfterViewInit,
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18nService } from './custom-datepicker-i18n.service';
import { FilteringPeriodTypeEnum, Period, TendersSearchService } from '../../services/tenders-search.service';
import { environment } from "../../../../environments/environment";
import { FilteringPeriod } from "../tenders-search/tenders-search.component";
import { BrowserDetectorService } from "../../../common-explain/services/browser-detector.service";
import { formatDateAsYYYYMMDD } from "../../../shared/helpers/date-helper";

export interface DbPeriod {
  from: Date | null;
  to: Date | null;
}

const MAXIMUM_YEARS_IN_FUTURE = 25;

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}]
})
export class FilterDateComponent implements AfterViewInit, OnChanges {
  ALLOWED_DATE_RANGE!: Record<FilteringPeriodTypeEnum, { min: NgbDate, max: NgbDate }>;
  FilteringPeriodTypeEnum = FilteringPeriodTypeEnum;
  filteringPeriodType = FilteringPeriodTypeEnum.INDEXATION;
  fromDate: Date | null = null;
  toDate: Date | null = null;
  startDate: Date;

  @Input() defaultPeriod?: FilteringPeriod;
  @Input({required: true}) tenderSearchService!: TendersSearchService;
  @Input({transform: booleanAttribute}) displayedEstimatedEndPeriod= false;

  @Output() periodSelected = new EventEmitter<{ period: Period | undefined, field: "indexation_period" | "estimated_end_period" }>();

  // TEMP WORK : Will be removed when the feature flag is removed
  showPastille = true;
  allowSearchByEstimatedEndPeriod = (environment as any)['allowSearchByEstimatedEndPeriod'];

  constructor(protected browser: BrowserDetectorService,
              private ngbCalendar: NgbCalendar) {
    this.ALLOWED_DATE_RANGE = {
      [FilteringPeriodTypeEnum.INDEXATION]: {min: new NgbDate(2015, 1, 1), max: this.ngbCalendar.getToday()},
      [FilteringPeriodTypeEnum.ESTIMATED_END]: {min: new NgbDate(2015, 1, 1), max: new NgbDate(this.ngbCalendar.getToday().year + MAXIMUM_YEARS_IN_FUTURE, 1, 1)},
    };
    const today = new Date();
    this.fromDate = today;
    this.toDate = today;
    this.startDate = today;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultPeriod'] && this.defaultPeriod) {
      this.fromDate = this.defaultPeriod[this.filteringPeriodType]?.from ?? new Date();
      this.toDate = this.defaultPeriod[this.filteringPeriodType]?.to ?? new Date();
    }
  }

  ngAfterViewInit() {
    this.updatePeriod();
    this.showPastille = !localStorage.getItem('new-filter-estimated-end-period-selector-feature-seen-2025_01_25');
  }

  updatePeriod() {
    this.periodSelected.emit({
      period: (this.fromDate && this.toDate) ? {
        from: formatDateAsYYYYMMDD(this.fromDate),
        to: formatDateAsYYYYMMDD(this.toDate)
      } : undefined,
      field: this.filteringPeriodType
    });
  }

  setDatePeriodMode(mode: FilteringPeriodTypeEnum, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.filteringPeriodType = mode;
    this.updatePeriod();
  }

  // TEMP WORK : Will be removed when the feature flag is removed
  managePastille() {
    this.showPastille = false;
    localStorage.setItem('new-filter-estimated-end-period-selector-feature-seen-2025_01_25', 'true');
  }
}

