import {
  booleanAttribute,
  Component, effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ApiTenderService } from '../../services/api/api-tender.service';
import { Tender } from '../../models/tender';
import { StatusItem } from '../../models/tender-status';
import { DropdownItem } from '../../../common-explain/components/ex-dropdown/ex-dropdown.component';
import { TendersModuleService } from '../../services/tenders-module.service';
import { ToastMessageStackService } from '../../../shared/services/toast-message-stack.service';

@Component({
  selector: 'app-tender-status-selector',
  templateUrl: './tender-status-selector.component.html',
  styleUrls: ['./tender-status-selector.component.scss']
})
export class TenderStatusSelectorComponent implements OnChanges {
  @Input({required: true}) tender!: Tender;
  @Input() tenderStatus?: StatusItem;
  @Input() size: 'small' | 'x-small' = 'x-small';
  @Input() placement: 'left' | 'right' = 'left';
  @Input({transform: booleanAttribute}) useShadow = false;
  @Output() statusChange = new EventEmitter<StatusItem>();
  @ViewChild('toast', { read: TemplateRef }) toast!: TemplateRef<any>;

  item?: DropdownItem<StatusItem>
  forceItem$ = new EventEmitter<DropdownItem | null | undefined>();
  forceToggle$ = new EventEmitter<boolean>();
  annotationId?: number;

  constructor(private apiTenderService: ApiTenderService,
              protected tendersModuleService: TendersModuleService,
              private toastMessageStackService: ToastMessageStackService) {
    effect(() => {
      const statuses = this.tendersModuleService.groupAccountStatuses();
      if (statuses.length && !statuses.some((item) => item.id === this.tender.statusId)) {
        this.tender.statusId = undefined;
        this.setItem(null);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tender']) {
      this.setItem(this.tender.statusId ? this.toDropdownItem({id: this.tender.statusId}) : null);
      this.annotationId = this.tender.tenderAnnotationsId;
    }
    if (changes['tenderStatus'] && this.tenderStatus) {
      this.setItem(this.toDropdownItem(this.tenderStatus));
    }
    if (changes['tenderStatus'] && this.tenderStatus === undefined) { // gestion du cas dé-qualification.
      this.setItem(null);
    }
  }

  setItem(status: DropdownItem<StatusItem> | null) {
    this.item = status ?
      this.tendersModuleService.statusItems.find((item) => item.value.id === status?.value.id) :
      undefined
    this.forceItem$.emit(this.item);
  }

  toDropdownItem(status: StatusItem): DropdownItem {
    return {
      label: status?.displayName ?? '',
      value: status ?? {id: 0}
    }
  }

  async onDropdownToggle(event: boolean) {
    if (event) await this.updateAnnotation();
  }

  async updateAnnotation() {
    const updatedAnnotation = await this.apiTenderService.annotation
      .getAnnotation(this.tender.id)
      .catch((error) => {
        if (error.status === 404) return undefined;
      });
    if (updatedAnnotation === undefined) return;
    this.annotationId = updatedAnnotation.id;
    this.setItem(this.toDropdownItem({id: this.tender.statusId ?? 0}));
    this.tender.statusId = this.tendersModuleService.statusItems.find((item) =>
      item.value.id === updatedAnnotation.status_id)?.value.id ?? undefined;
  }

  async quickQualification(event: any) {
    event.stopPropagation();
    event.preventDefault();
    // On met à jour la valeur du statut.
    await this.updateAnnotation();
    // dans le cas où l'item est déjà sélectionné, on ne fait rien
    if (this.item?.value?.id) {
        // si déjà qualifié, alors on dé-qualifie
        await this.removeStatus();
    } else { // sinon on sélectionne le premier item, i.e. "opportunité"
      await this.updateAnnotationValue(this.tendersModuleService.statusItems[0])
    }
  }

  async updateAnnotationValue(event?: DropdownItem<StatusItem> | null) {
    if (event) {
      this.statusChange.emit(event.value);
      if (this.annotationId) {
        await this.apiTenderService.annotation.updateAnnotation({
          id: this.annotationId,
          status_id: event.value.id
        }).then(() => {
          this.setItem(event)
          this.tender.statusId = event.value.id!;
        }).catch(
          () => this.toastMessageStackService.show(this.toast, {autohide: true, classname: 'error-toast toast-shape'})
        );
      } else {
        await this.apiTenderService.annotation.addAnnotation({
          tender_uid: this.tender.id,
          status_id: event.value.id!,
        }).then((res) => {
          if (res.tender_annotation_id === null) this.forceItem$.emit(undefined);
          this.setItem(event)
          this.tender.statusId = event.value.id!;
          this.tender.tenderAnnotationsId = res.tender_annotation_id;
          this.annotationId = res.tender_annotation_id;
        }).catch(
          () => this.toastMessageStackService.show(this.toast, {autohide: true, classname: 'error-toast toast-shape'})
        );
      }
    }
  }

  async removeStatus() {
    await this.apiTenderService.annotation.updateAnnotation({
      id: this.annotationId ?? 0,
      status_id: null
    }).catch(() => {
        this.toastMessageStackService.show(this.toast, {autohide: true, classname: 'error-toast toast-shape'});
      });
    this.setItem(null);
    this.statusChange.emit(undefined); // emission de la valeur *avant* l'affectation du status à undef.
    this.tender.statusId = undefined;
    this.forceItem$.emit(undefined);
  }
}
