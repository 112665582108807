import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastMessageService } from '../../shared/services/toast-message.service';
import { ApiService } from '../../shared/services/api/api.service';
import { first } from 'rxjs/operators';
import { User } from '../../models/user';

import MEPConfiguration from 'src/assets/mep-configuration/version.json';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit {
  @Input() editMode!: boolean;
  @Input() returnUrl!: string;
  @Input() title!: string;
  @Input() content!: string;
  @Input() content2!: string;
  @Input() btnName!: string;
  @Input() alertMode!: boolean;

  @Input() isNewFeatureExplainedModal?: boolean; // boolean to manage new-feature-description EXPORT

  // Part concerning modal update Mode
  @Input() updateMode = false;
  @Input() snooze = false;
  @Input() explanation!: string;
  @Input() explanation2!: string;
  @Input() explanation3!: string;
  @Input() explanationUnderline!: string;
  @Input() snoozeFunction!: Function;
  snoozeButtonClickedId = 'snoozeButtonClickedId';
  // Delay to differ redirection after cancellation.
  delayToApplyOnCancellation = 0;
  // Function we want to be triggered on Modale cancellation.
  cancellationFunction = () => new Observable<any>();

  constructor(private modalService: NgbModal,
              private router: Router,
              private activeModal: NgbActiveModal,
              private apiService: ApiService,
              private toastMessageService: ToastMessageService) {
  }

  ngOnInit(): void {
  }

  cancelConfirm() {
    // Triggering of custom function.
    this.cancellationFunction().subscribe();
    // Delayed closing and redirection.
    setTimeout(() => {
      this.toastMessageService.setFinished(false);
      this.modalService.dismissAll();
      this.router.navigate([this.returnUrl]).then();
    }, this.delayToApplyOnCancellation);
  }

  async getOwnUserInfo(): Promise<User | null | undefined> {
    try {
      return await this.apiService.user.getOwnUser().pipe(first()).toPromise();
    } catch (error) {
      console.log(`updateConfirm get user info method leads to error - ${error} - reload`);
      window.location.reload();
      return null;
    }
  }

  /** reload of the application after a new version of the app is available */
  async updateConfirm() {
    console.log('update modal updateConfirm clicked');
    if (!localStorage.getItem('email') || !localStorage.getItem('group_account_id')) {
      const user = await this.getOwnUserInfo();
      localStorage.setItem('email', user?.email || '');
      localStorage.setItem('group_account_id', String(user?.groupAccountId));
    }
    console.log('update modal updateConfirm clicked - normal reload');
    window.location.reload();
  }

  /** New feature explained modal __ Setting local storage item true */
  async onCloseNewFeatureExplainedModal() {
    localStorage.setItem(MEPConfiguration.new_feature_localstorage_item_name, 'true');
    this.close();
  }

  close() {
    this.activeModal.close();
  }

  snoozeAndClose() {
    console.log('update modal snoozeAndClose clicked');
    this.snoozeFunction();
    this.activeModal.close();
  }
}
