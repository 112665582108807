<div class="input-container">
  <i class="ph ph-magnifying-glass"></i>
  <input #searchInput type="text"
         [placeholder]="(editable ? 'tenders.status.search-or-create' : 'tenders.status.search') | translate"
         [formControl]="filterText"
         maxlength="20"
         (keydown.enter)="$event.preventDefault(); $event.stopPropagation()"
         (keydown.tab)="$event.preventDefault(); $event.stopPropagation()"
         (keyup.enter)="validateSelection()"
         (keyup.tab)="copySelection()"
         (keydown.arrowUp)="moveSelection('up')"
         (keydown.arrowDown)="moveSelection('down')"
         (blur)="currentSelectionIndex = 0"
         autofocus
  />
  <i *ngIf="filterText.value.length"
     (click)="$event.stopPropagation();filterText.setValue('');searchInput.focus()"
     class="ph ph-x-circle cursor-pointer"></i>
</div>
<div #statusContainer class="status-container" cdkScrollable>
  <div *ngIf="filteredDefaultItems.length"  class="status-section">
    <div class="section-title" (click)="$event.stopPropagation();collapseDefaultItems = !collapseDefaultItems">
        <div>
            {{ "tenders.status.default-statuses" | translate | uppercase }}
        </div>
        <div class="collapse-toggle">
            @if (collapseDefaultItems) {
                <i class="ph-bold ph-caret-down"></i>
            } @else {
                <i class="ph-bold ph-caret-up"></i>
            }
        </div>
    </div>
    @for (status of filteredDefaultItems; let index = $index ;track status.value.id) {
      <ng-container *ngIf="!collapseDefaultItems" [ngTemplateOutlet]="itemRow"
                    [ngTemplateOutletContext]="{$implicit: status, index}"/>
    }
  </div>
  <div *ngIf="filterText.value ? filteredCustomItems.length : customItems.length || showNone" class="status-section">
    <div class="section-title" (click)="$event.stopPropagation();collapseCustomItems = !collapseCustomItems">
        <div>
            <span>
                {{ "tenders.status.custom-statuses" | translate | uppercase }}
            </span>
            <span class="new-tag">
                {{ 'common.new' | translate | uppercase }}
            </span>
        </div>
        <div class="collapse-toggle">
            @if (collapseCustomItems) {
                <i class="ph-bold ph-caret-down"></i>
            } @else {
                <i class="ph-bold ph-caret-up"></i>
            }
        </div>
    </div>
    <div *ngIf="!filterText.value && !customItems.length && !collapseCustomItems && showNone" class="no-status">
        {{ 'tenders.status.none' | translate }}
    </div>
    @for (status of filteredCustomItems; let index = $index; track status.value.id) {
      <ng-container *ngIf="!collapseCustomItems" [ngTemplateOutlet]="itemRow"
                    [ngTemplateOutletContext]="{$implicit: status, index: index + filteredDefaultItems.length}"/>
    }
  </div>
  <div *ngIf="filterText.value && editable" class="status-section">
    <div class="status-item d-flex align-items-center mt-1"
         [ngClass]="{'disabled': nameExists() || loading || customItems.length > STATUS_COUNT_LIMIT,
                     'selected': currentSelectionIndex === filteredItems.length}"
         (click)="createStatus()">
      <div class="plus-icon">
        <i class="ph-bold ph-plus"></i>
      </div>
      <div class="flex-grow-1 text-truncate">
        <span>{{ 'tenders.status.create' | translate }}</span>
        <span class="fw-bolder ms-1">"{{ filterText.value.trim() }}"</span>
      </div>
    </div>
  </div>
</div>
<ng-template #itemRow let-elm let-index="index">
  <div class="status-item d-flex align-items-center"
       (click)="itemSelect.emit(elm)"
       [ngClass]="{'active': elm.value.id === (item?.value?.id ?? -1), 'selected': index === currentSelectionIndex}"
  >
    <svg class="color-square" width="16" height="16" fill="currentColor"
         [style.color]="computeColor(elm)">
      <path d="M12 14.5H4C2.89733 14.5 2 13.6027 2 12.5V4.5C2 3.39733 2.89733 2.5 4 2.5H12C13.1027 2.5 14 3.39733 14 4.5V12.5C14 13.6027 13.1027 14.5 12 14.5Z" fill="currentColor"/>
    </svg>
    <div class="text-truncate" [innerHTML]="elm.label | emphasizeFilter: filterText.value"></div>
    <div *ngIf="withCounter">({{getCountOf(elm.value.id)}})</div>
    <div *ngIf="elm.value.status_type === 'custom' && editable" class="status-action-btn hover-only"
         [ngClass]="{'active': currentStatusEdition?.id === elm.value.id}"
         cdkOverlayOrigin #trigger="cdkOverlayOrigin"
         (click)="$event.stopPropagation(); currentStatusEdition = { id: elm.value.id }"
         [ngbTooltip]="'tenders.status.edit-status' | translate"
         [openDelay]="500"
         tooltipClass="simple-tooltip fit"
         placement="bottom auto"
    >
      <i class="ph-fill ph-gear"></i>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="currentStatusEdition?.id === elm.value.id"
        (detach)="currentStatusEdition = undefined"
        (overlayOutsideClick)="handleOutsideClick($event, elm.value.id)"
        [cdkConnectedOverlayOffsetX]="4"
        [cdkConnectedOverlayOffsetY]="4"
      >
        <div class="status-edit ex-shadow">
          <div class="option">
            <i class="ph-fill ph-pencil-simple"></i>
            <input #renameInput type="text" maxlength="20" [defaultValue]="elm.label"
                   (input)="renameInputChange(renameInput.value)"
                   (keyup.enter)="closeStatusEdition()"
                   autoselect
                   [ngClass]="{'disabled': loading}"
            >
          </div>
          <div class="divider"></div>
          <div class="option delete-option" (click)="deleteStatus()">
            <i class="ph-fill ph-trash"></i>
            <span class="fw-semibold">{{ 'tenders.status.delete' | translate }}</span>
          </div>
        </div>
      </ng-template>
    </div>
    <div *ngIf="elm.value.id === (item?.value?.id ?? -1)"
         class="status-action-btn ms-auto"
         (click)="itemSelect.emit(null)"
         [ngbTooltip]="'tenders.status.remove-status' | translate"
         [openDelay]="500"
         tooltipClass="simple-tooltip fit"
         placement="bottom auto"
    >
      <i class="ph-bold ph-x"></i>
    </div>
  </div>
</ng-template>
