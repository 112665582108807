<div class="relevance-indicator"
     [ngbTooltip]="'tenders.badge.suggestion' | translate"
     placement="bottom-start top-start auto"
     tooltipClass="tender-suggestion-tooltip"
     [openDelay]="500"
     [positionTarget]="icon._elementRef.nativeElement"
>
  <mat-icon #icon svgIcon="sparks-filled" class="icon-ai" />
  <span class="relevance-text">{{ 'tenders.relevance-indicator.text' | translate }}</span>
</div>
