import { DbDocumentEntity, DocumentEntity } from './document-entity';
import { MatchStem } from './match-stem';
import { TerritoryForAdminDoc } from './territory/territory-utils';

export enum ProjectDocumentSourceEnum {
  MRAE = 'MRAE',
  PREFECTURE = 'PREF'
}

export enum RegionDepaDocumentSourceEnum {
  FRREGI = 'FRREGI',
  FRDEPA = 'FRDEPA',
}

export enum AdminDocSourceEnum {
  FRCOMM = 'FRCOMM',
  FREPCI = 'FREPCI',
  SYNDICAT = 'SYND',
  EPTP = 'EPTP'
}


export interface DbAdminDocument extends DbDocumentEntity {
  id: string;
  publication_type: any;
  url: string;
  website: string;
  source: string;
  displayed_publication_type: string;
  match_stems?: MatchStem[];
  territories?: TerritoryForAdminDoc[];
  inquirers?: string[];
  project_uid?: string;
}

export class AdminDocument extends DocumentEntity {

  private _id: string;
  private _publicationType: any;
  private _url: string;
  private _website: string;
  private _source: string;
  private _displayedPublicationType: string;
  private _matchStems?: MatchStem[];
  private _territories?: any;
  private _projectUid?: string;

  constructor(dbAdminDocument: DbAdminDocument) {
    super(dbAdminDocument as DbDocumentEntity);
    this._id = dbAdminDocument.id;
    this._publicationType = dbAdminDocument.publication_type;
    this._url = dbAdminDocument.url;
    this._website = dbAdminDocument.website;
    this._source = dbAdminDocument.source;
    this._displayedPublicationType = dbAdminDocument.displayed_publication_type;
    this._matchStems = dbAdminDocument.match_stems;
    this._territories = dbAdminDocument.territories?.filter( territory => territory.displayed);
    this._projectUid = dbAdminDocument.project_uid || '';
  }


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get publicationType(): any {
    return this._publicationType;
  }

  set publicationType(value: any) {
    this._publicationType = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get website(): string {
    return this._website;
  }

  set website(value: string) {
    this._website = value;
  }

  get source(): string {
    return this._source;
  }

  set issuerKind(value: string) {
    this._source = value;
  }

  get displayedPublicationType(): string {
    return this._displayedPublicationType;
  }

  set displayedPublicationType(value: string) {
    this._displayedPublicationType = value;
  }

  get matchStems(): MatchStem[] | undefined {
    return this._matchStems;
  }

  set matchStems(value: MatchStem[] | undefined) {
    this._matchStems = value;
  }

  get territories(): any {
    return this._territories;
  }

  set territories(value: any) {
    this._territories = value;
  }

  get projectUid(): string | undefined {
    return this._projectUid;
  }

  set projectUid(value: string) {
    this._projectUid = value;
  }
}
