import { SubKind, TerritoryKind } from '../../core/territory-kind/territory-kind.enum';

export interface DbTerritory {
  code: string;
  kind: TerritoryKind | '';
  subkind?: SubKind;
  syndicat_scopes?: string[];
  syndicat_name?: string;
  name: string;
  search_key: string;
  outside_department_count?: number;
  uid: string;
  territories: Array<DbTerritory>;
  region?: DbTerritory;
  admin_doc_count?: number;
  article_count?: number;
}

export class Territory {
  private _code: string;
  private _kind: TerritoryKind | '';
  private _subkind?: SubKind;
  private _syndicateScopes: string[];
  private _syndicateName: string;
  private _name: string;
  private _searchKey: string;
  private _outside_department_count: number;
  private _uid: string;
  private _adminDocCount?: number;
  private _articleCount?: number;
  private _territories: Array<Territory>;
  private _region?: Territory;

  constructor(dbTerritory: DbTerritory) {
    this._code = dbTerritory.code;
    this._kind = dbTerritory.kind;
    this._subkind = dbTerritory.subkind;
    this._syndicateScopes = dbTerritory.syndicat_scopes || [];
    this._syndicateName = dbTerritory.syndicat_name || '';
    this._name = dbTerritory.name;
    this._searchKey = dbTerritory.search_key;
    this._outside_department_count = dbTerritory.outside_department_count || 0;
    this._uid = dbTerritory.uid;
    this._adminDocCount = dbTerritory.admin_doc_count;
    this._articleCount = dbTerritory.article_count;
    this._territories = dbTerritory.territories.map((territory) => new Territory(territory));
    this._region = dbTerritory.region ? new Territory(dbTerritory.region) : undefined;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get kind(): TerritoryKind | '' {
    return this._kind;
  }

  set kind(value: TerritoryKind | '') {
    this._kind = value;
  }

  get subkind(): SubKind | undefined {
    return this._subkind;
  }

  get syndicateScopes(): string[] {
    return this._syndicateScopes;
  }

  get isSyndicate() {
    return this.subkind === SubKind.SYNDICAT;
  }

  get syndicateName(): string {
    return this._syndicateName;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get searchKey(): string {
    return this._searchKey;
  }

  set searchKey(value: string) {
    this._searchKey = value;
  }

  get outside_department_count(): number {
    return this._outside_department_count;
  }

  set outside_department_count(value: number) {
    this._outside_department_count = value;
  }

  get uid(): string  {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get adminDocCount(): number | undefined {
    return this._adminDocCount;
  }

  set adminDocCount(value: number) {
    this._adminDocCount = value;
  }

  get articleCount(): number | undefined {
    return this._articleCount;
  }

  set articleCount(value: number) {
    this._articleCount = value;
  }

  get territories(): Array<Territory> {
    return this._territories;
  }

  set territories(value: Array<Territory>) {
    this._territories = value;
  }

  get region(): Territory | undefined{
    return this._region;
  }

  set region(value: Territory) {
    this._region = value;
  }
}

export const allFrenchRegionCodes = [
  'FRREGI32',
  'FRREGI53',
  'FRREGI24',
  'FRREGI84',
  'FRREGI94',
  'FRREGI75',
  'FRREGI11',
  'FRREGI27',
  'FRREGI28',
  'FRREGI44',
  'FRREGI93',
  'FRREGI76',
  'FRREGI52',
  'DROM',
  'COM',
];
