import {
  Component, effect,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, } from "@angular/forms";
import { NgbCalendar, NgbDate, NgbDatepicker, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { DbPeriod } from "../../../tenders/components/filter-date/filter-date.component";
import {
  ExDropdownV2Component,
  ExDropdownV2ContentDirective,
  ExDropdownV2HeaderDirective
} from "../ex-dropdown-v2/ex-dropdown-v2.component";
import { NgClass } from "@angular/common";
import { BrowserDetectorService } from "../../services/browser-detector.service";

@Component({
  selector: 'ex-date-selector',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgbDatepicker,
    FormsModule,
    TranslateModule,
    ExDropdownV2Component,
    ExDropdownV2HeaderDirective,
    ExDropdownV2ContentDirective,
    NgClass
  ],
  templateUrl: './ex-date-selector.component.html',
  styleUrl: './ex-date-selector.component.scss'
})
export class ExDateSelectorComponent implements OnChanges {
  @ViewChild('customDatePicker') datepicker!: NgbDatepicker;
  @ViewChild('dateInput') dateInput!: HTMLInputElement;

  @Input({required: true}) inputDate!: Date; // date fournie en input
  @Input({required: true}) inputPeriod!: DbPeriod; // date fournie en input

  @Input() tip: 'from' | 'to' = 'from';
  @Input() explanations?: string;
  @Input() allowedDateRange?: { min: NgbDate, max: NgbDate };
  @Input() periodNotValid = false;

  @Output() dateSelect = new EventEmitter<Date | null>();

  today!: NgbDate;
  ngbMonthYear!: { year: number, month: number };
  hoveredDate: NgbDate | null = null;
  minDateInput: Date = new Date;
  maxDateInput: Date = new Date;

  // différents attributs de date
  selectedDate = signal(new Date());
  inputDateString = '';
  selectedNgbDate!: NgbDate;
  inputFocus = false;

  constructor(protected browser: BrowserDetectorService,
              private ngbCalendar: NgbCalendar) {
    this.today = this.ngbCalendar.getToday()
    effect(() => {
      this.selectedNgbDate = getNgbDate(this.selectedDate());
      this.inputDateString = getDateString(this.selectedDate());
      this.updateNgbMonthYear(this.inputDateString);
      this.datepicker?.navigateTo(this.ngbMonthYear);
      this.onSubmit();
    });
  }

  ngOnChanges(changes:SimpleChanges) {
    if (changes['inputDate'] && this.inputDate) {
      this.selectedDate.set(this.inputDate);
    }
    if (changes['allowedDateRange'] && this.allowedDateRange) {
      this.minDateInput = this.getDateFromNgbDate(this.allowedDateRange?.min);
      this.maxDateInput = this.getDateFromNgbDate(this.allowedDateRange?.max);
    }
  }

  updateNgbMonthYear(tempValue?: string) {
    const element = tempValue || this.inputDateString;
    const [year, month] = element.split('-').map(Number);
    this.ngbMonthYear = { year, month };
  }

  isInvalid() {
    const [year, month, day] = this.inputDateString.split('-').map(Number);
    return (isNaN(year) || !year || isNaN(month) || !month || isNaN(day) || !day);
  }

  onModelChange(ngbDate: NgbDate) {
    this.selectedDate.set(new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day));
  }

  onInputDateStringChange(value: string) {
    // STOP if input is ''
    if (!value) return;
    // management of year value.
    const [year] = value.split('-').map(Number);
    if (year < 1000) return; // STOP if year is not complete
    this.checkAndAdjustInputDateString(value);
  }

  checkAndAdjustInputDateString(value: string, updateValue = false) {
    const [year, month, day] = value.split('-').map(Number);
    let [_year, _month, _day] = [year, month, day];
    const [minYear, maxYear] = [this.minDateInput.getFullYear(), this.maxDateInput.getFullYear()];
    const [minMonth, maxMonth] = [this.minDateInput.getMonth() + 1, this.maxDateInput.getMonth() + 1];
    const [minDay, maxDay] = [this.minDateInput.getDate(), this.maxDateInput.getDate()];

    // Clamp year to valid range
    _year = Math.min(Math.max(year, minYear), maxYear);
    // Handle month/day constraints for min/max years
    if (_year === minYear) {
      _month = Math.max(month, minMonth);
      _day = (_month === minMonth) ? Math.max(day, minDay) : day;
    }
    if (_year === maxYear) {
      _month = Math.min(month, maxMonth);
      _day = (_month === maxMonth) ? Math.min(day, maxDay) : day;
    }
    if (!updateValue) return; // STOP
    this.inputDateString = '';
    setTimeout(() => {
      this.selectedDate.set(new Date(_year, _month - 1, _day));
    });
  }

  onSubmit() {
    if (this.inputFocus) return;
    this.dateSelect.next(this.selectedDate() ?? null);
  }

  isFocused(date: NgbDateStruct) {
    return (date.year === this.selectedNgbDate?.year)
      && (date.month === this.selectedNgbDate?.month)
      && (date.day === this.selectedNgbDate?.day);
  }

  getDateFromNgbDate(ngbDate: NgbDate) {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  getDateStringFromNgbDate(ngbDate?: NgbDate) {
    if (!ngbDate) return '';
    const day = ngbDate.day;
    const month = ngbDate.month
    return `${ngbDate.year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  }

  navigate(event: { current: {year: number, month: number} | null, next: {year: number, month: number} }) {
    this.ngbMonthYear = event.next
    const targetDate = new Date(
      event.next.year,
      event.next.month - 1,
      Math.min(this.selectedDate().getDate(), new Date(event.next.year, event.next.month, 0).getDate())
    );
    this.selectedDate.set(targetDate);
  }

}

function getNgbDate(date: Date) {
  return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

function getDateString(date?: Date) {
  if (!date) return '';
  const day = date.getDate();
  const month = date.getMonth() + 1
  return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
}
