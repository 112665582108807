import { Component } from '@angular/core';
import { TendersModuleService } from '../../services/tenders-module.service';

@Component({
  selector: 'app-tenders',
  templateUrl: './tenders.component.html',
  styleUrls: ['./tenders.component.scss'],
  providers: [TendersModuleService]
})
export class TendersComponent {

}
