import {
  booleanAttribute,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges, Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Tender} from '../../models/tender';
import { ToastMessageStackService } from '../../../shared/services/toast-message-stack.service';
import { ModalShareWatchComponent } from '../modal-share-watch/modal-share-watch.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TenderTerritoriesTextService } from "../../services/helpers/tender-territories-text.service";
import { getSanitizedString } from "../../services/helpers/string-helper";
import { SmartGridService } from "../../services/smart-grid.service";
import { TendersModuleService } from "../../services/tenders-module.service";
import { ApiTenderService } from "../../services/api/api-tender.service";
import { DceDocument } from "../../models/dce-document";

@Component({
  selector: 'app-tender-share',
  templateUrl: './tender-share-btn.component.html',
  styleUrls: ['./tender-share-btn.component.scss']
})
export class TenderShareBtnComponent implements OnChanges {

  @Input({required: true}) tender!: Tender;
  @Input() size: 'small' | 'x-small' = 'x-small';
  @Input({transform: booleanAttribute}) displayShareIcon = false;
  @Input({transform: booleanAttribute}) isDetailView = false;
  @Input({transform: booleanAttribute}) useShadow = false;
  @Input({required: true}) smartGridService!: SmartGridService;
  @Output() updatedTender = new EventEmitter<Tender>();
  inquirers!: string;
  departments!: string;

  @ViewChild('tenderListActionToast', {read: TemplateRef}) tenderListActionToast!: TemplateRef<any>;

  constructor(protected tenderTerritoriesTextService: TenderTerritoriesTextService,
              private toastMessageStackService: ToastMessageStackService,
              private modalService: NgbModal,
              private injector: Injector,
              private apiTenderService: ApiTenderService,
              private tendersModuleService: TendersModuleService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tender']) {
      this.inquirers = getSanitizedString((this.tender.inquirers ?? []).join(', '));
      this.departments = this.tenderTerritoriesTextService.getCompactCodeString(this.tender.territories);
    }
  }

  async shareMarket() {
    await this.updateTenderWorkData();
    if (!this.isDetailView && !this.tender.dceProjectUid) {
      // @ts-ignore - On surcharge la classe Tender
      this.tender['dceDocuments'] = (await this.apiTenderService.tenderDetail.getTenderDetail(this.tender.id))?.dce_documents.map((dbDoc: any) => new DceDocument(dbDoc)) ?? [];
    }
    if (this.tender.dceProjectUid) this.smartGridService.setProjectUid(this.tender.dceProjectUid).then();
    else this.smartGridService.resetAllAttributes();
    const modalRef = this.modalService.open(
      ModalShareWatchComponent,
      {ariaLabelledBy: 'modal-share-watch', centered: true, windowClass: 'modal-auto', injector: this.injector});
    modalRef.componentInstance.tender = this.tender;
    modalRef.componentInstance.inquirers = this.inquirers;
    modalRef.componentInstance.department = this.departments;
    modalRef.componentInstance.note = this.tender.note ?? '';
    modalRef.componentInstance.smartGridService = this.smartGridService;
    modalRef.result.then((contentToCopy) => {
      this.copyEnrichedTextFromHtml(contentToCopy);
    }).catch(() => null);
  }

  copyEnrichedTextFromHtml(state: 'success' | 'error') {
    this.showToast('copy', state);
  }

  async updateTenderWorkData() {
    await this.apiTenderService.annotation.updateTenderWorkData(this.tender);
    this.updatedTender.emit(this.tender);
    this.tendersModuleService.updateTenderInRegisteredServices(
      this.tender.id, { note: this.tender.note, assignedUserId: this.tender.assignedUserId, tenderAnnotationsId: this.tender.tenderAnnotationsId }
    );
  }

  showToast(action: 'copy', message: 'success' | 'error', entityName?: string) {
    let options: any = {autohide: true, classname: message + '-toast-tenders' + ' toast-shape ' + action, action: action};
    if (entityName) options = {...options, name: entityName};
    this.toastMessageStackService.show(this.tenderListActionToast, options);
  }
}
