import { booleanAttribute, Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[autoselect]'
})
export class AutoselectDirective implements OnInit {
  private select = true;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.select)
    {
      //Otherwise Angular throws error: Expression has changed after it was checked.
      window.setTimeout(() =>
      {
        this.el.nativeElement.focus(); //For SSR (server side rendering) this is not safe. Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
        this.el.nativeElement.select();
      });
    }
  }

  @Input({transform: booleanAttribute}) set autoselect(condition: boolean | string) {
    this.select = !!condition;
  }
}
