import { Component, Input } from '@angular/core';
import { TenderTerritoriesTextService } from "../../services/helpers/tender-territories-text.service";
import { TenderTerritoryLight } from "../../models/tender-territory";
const MAX_SHOWN_TERRITORIES = 10;
const REDUCED_SHOWN_TERRITORIES = 2;
@Component({
  selector: 'app-inline-territories-display',
  templateUrl: './inline-territories-display.component.html',
  styleUrl: './inline-territories-display.component.scss'
})
export class InlineTerritoriesDisplayComponent {

  @Input({required: true}) territories!: TenderTerritoryLight[];
  @Input() maximumShownTerritories = MAX_SHOWN_TERRITORIES;
  @Input() reducedShownTerritories = REDUCED_SHOWN_TERRITORIES;

  constructor(
    protected tenderTerritoriesTextService: TenderTerritoriesTextService) {
  }

}
