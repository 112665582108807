import { Component, Input } from '@angular/core';
import { TenderDetail } from '../../models/tender-detail';
import { hasNonZeroTime } from '../../services/helpers/date-formater';
import { TenderTypesEnum } from '../../models/tender-search-body';

@Component({
  selector: 'app-tender-metadata',
  templateUrl: './tender-metadata.component.html',
  styleUrls: ['./tender-metadata.component.scss']
})
export class TenderMetadataComponent {
  protected readonly hasNonZeroTime = hasNonZeroTime;
  protected readonly TenderTypesEnum = TenderTypesEnum;

  @Input() tenderDetail!: TenderDetail;

}

