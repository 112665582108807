import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subject, takeUntil, timer } from "rxjs";

@Directive({
  selector: '[longHoverOnElement]',
  standalone: true
})
export class LongHoverDirective {
  @Input('longHoverCallBack') callback?: () => void;
  @Output('longHoverTriggered') method = new EventEmitter<void>();

  private mouseEnter = new Subject<void>();
  private mouseLeave = new Subject<void>();
  HOVER_TIME = 2000

  constructor() {
    this.setUp();
  }

  setUp() {
    this.mouseEnter = new Subject<void>();
    this.mouseEnter.pipe(
      takeUntil(this.mouseLeave)
    ).subscribe(() => {
      timer(this.HOVER_TIME)
        .pipe(
        takeUntil(this.mouseLeave)
      ).subscribe(() => {
        this.callback?.();
        this.method.emit();
      });
    });
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.setUp();
    this.mouseEnter.next();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.mouseLeave.next();
  }
}
