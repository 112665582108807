import Quill from "quill";
import Embed from "quill/blots/embed.js";

export default class PinnedQuestionBlot extends Embed {

  static override create(value: { promise: Promise<any>, text: string, embedType: 'pinned-question' | 'summary'}) {
    let node = super.create(value);
    // @ts-expect-error
    node.innerText = value.text;
      // @ts-expect-error
    node.classList.add(value.embedType);
    value.promise?.then((value) => {
      // @ts-expect-error
      node.innerText = value;
    });
    return node
  }
}
PinnedQuestionBlot.blotName = 'pinned-question'
PinnedQuestionBlot.tagName = 'span'
PinnedQuestionBlot.className = 'embed-pinned-question'

Quill.register(PinnedQuestionBlot)
