<div class="metadata-content d-flex flex-column ls-m1">

  <!-- dates de publication et de fin -->
  <div class="metadata-group dates-container">
    <div class="d-flex align-items-center gap-4px">
      <i class="ph-fill ph-calendar-check flex-center-start"></i>
      <span>
        {{ 'tenders.metadata.from' | translate }}
      </span>
      <!--date de publication définie-->
      <span *ngIf="tenderDetail.indexationDate.getTime() > 0" class="important ls-m2">
      {{ tenderDetail.indexationDate | date:'dd/MM/yyyy' }}</span>
      <!--date de publication nulle ou undef-->
      <span *ngIf="tenderDetail.indexationDate.getTime() === 0" class="null-value ls-m2">
      {{ 'tenders.unset-values.undetected' | translate }}</span>
    </div>

    <div class="d-flex gap-4px">
      <span>
        {{ 'tenders.metadata.to' | translate }}
      </span>
      <!--date de fermeture définie-->
      <ng-container *ngIf="tenderDetail.closingDate && (tenderDetail.closingDate.getTime() > 0)">
        <span *ngIf="tenderDetail.closingDate && hasNonZeroTime(tenderDetail.closingDate)" class="important ls-m2">
          {{ tenderDetail.closingDate | date:'dd/MM/yyyy à HH:mm' }}</span>
        <span *ngIf="tenderDetail.closingDate && !hasNonZeroTime(tenderDetail.closingDate)" class="important ls-m2">
          {{ tenderDetail.closingDate | date:'dd/MM/yyyy' }}</span>
      </ng-container>
      <!--date de fermeture nulle ou undef-->
      <span *ngIf="tenderDetail.tenderType === TenderTypesEnum.ATTRIBUTION">-</span>
      <span *ngIf="tenderDetail.tenderType !== TenderTypesEnum.ATTRIBUTION &&
              (!tenderDetail.closingDate || tenderDetail.closingDate && (tenderDetail.closingDate?.getTime() === 0))"
            class="null-value"
      >
        {{ 'tenders.unset-values.undetected' | translate }}
      </span>
    </div>
  </div>

  <div *ngIf="tenderDetail.assignees || tenderDetail.amount" class="d-flex metadata-item text-nowrap flex-wrap ls-m2">
    <i class="flex-center-start ph-fill ph-trophy"></i>
    @if (tenderDetail.assignees && tenderDetail.assignees.length) {
      <span class="fw-semibold text-truncate assignee"
            [ngbTooltip]="tenderDetail.assignees[0]"
            overflowTooltip
            tooltipClass="simple-tooltip"
            placement="bottom auto"
            container="body"
      >{{tenderDetail.assignees[0]}}</span>
      <span *ngIf="tenderDetail.assignees.length > 1" class="color-basic-600 text-nowrap other-assignees"
            [ngbTooltip]="tenderDetail.assignees.slice(1, tenderDetail.assignees.length).join('\n')"
            tooltipClass="simple-tooltip text-left"
            placement="bottom auto"
            container="body"
      >
      {{ 'tenders.metadata.and-x-assignee' + (tenderDetail.assignees.length - 1 > 1 ? "s" : "")
        | translate: {count: tenderDetail.assignees.length - 1} }}</span>
    } @else {
      <span class="null-value">{{ 'tenders.unset-values.assignees' | translate }}</span>
    }
    <span class="d-flex">
      <span class="attribution-separator pdr-4px">·</span>
      <app-tender-amount [amount]="tenderDetail.amount" [undetectedTranslationKey]="'unset-values.amount'"/>
    </span>
  </div>



  <div *ngIf="tenderDetail.estimatedEndDate" class="d-flex metadata-item ls-m2">
    <i class="flex-center-start ph-bold ph-clock-clockwise"></i>
    <span>{{ 'tenders.metadata.estimated-end-date' | translate }}</span>
    <span class="fw-semibold">{{ tenderDetail.estimatedEndDate.toLocaleDateString('fr-FR', {
      month: "long", year: "numeric"
    }) }}</span>
  </div>

  <div class="d-flex metadata-item ls-m2">
    <i class="flex-center-start ph-fill ph-users"></i>
    <span class="fw-semibold text-truncate"
          [ngbTooltip]="tenderDetail.inquirers?.join(',')"
          [disableTooltip]="!tenderDetail.inquirers?.length"
          overflowTooltip
          tooltipClass="simple-tooltip"
          placement="bottom auto"
    >{{ tenderDetail.inquirers?.join(',') || 'tenders.unset-values.inquirers' | translate | removeDepartmentNumbers }}</span>
    <app-inline-territories-display [territories]="tenderDetail.territories"/>
  </div>
</div>
