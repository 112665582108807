<div class="new-item-modal">
  <div class="modal-header">
    <h4 *ngIf="ready" class="modal-title">
      {{(translationFileSection + '.modal-' + (updateMode ? 'edit' : 'delete') + '-item' +
        ((totalItemsCount ?? 0) > 1 ? 's' : '') + '-title') | translate: {count: totalItemsCount} }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()">
    </button>
  </div>
  @if (ready) {
    <div class="modal-body">
      <div class="modal-sub-title">
        <div *ngIf="updateMode">
          {{ translationFileSection + '.modal-edit-item-sub-title' | translate }}
        </div>
        <div *ngIf="deleteMode && affectedItemsCount">
        <span>{{(translationFileSection + '.modal-delete-item-sub-title-item' +
          (affectedItemsCount > 1 ? 's' : '') + '-prefix') | translate }}</span>
          <span class="highlight">{{(translationFileSection + '.modal-delete-item-sub-title-item' +
            (affectedItemsCount > 1 ? 's' : '')) | translate: {count: affectedItemsCount} }}</span>
          <span>{{(translationFileSection + '.modal-delete-item-sub-title-item' +
            (affectedItemsCount > 1 ? 's' : '') + '-suffix') | translate }}</span>
        </div>
        <div>{{ deleteMode && (translationFileSection + '.modal-delete-item' +
          ((totalItemsCount ?? 0) > 1 ? 's' : '') + '-sub-title' | translate: {name: targetValue})}}</div>
      </div>
      <div class="modal-checkbox">
        <label class="container d-flex">
                <span class="checkbox-label">
                  {{(translationFileSection + '.modal-' + (updateMode ? 'edit' : 'delete') + '-item' +
                  ((totalItemsCount ?? 0) > 1 ? 's' : '') + '-checkbox') | translate: {count: totalItemsCount} }}
                </span>
          <input type="checkbox" class="form-check-input"
                 [checked]="checked"
                 (change)="onCheckedChange()"
          >
          <span class="checkmark"></span>

        </label>

      </div>
    </div>
    <div class="modal-footer justify-content-end">

      <button class="ex-btn-optional-transparent ex-btn"
              (click)="dismissModal()"
      >
        {{translationFileSection + '.modal-cancel' | translate}}
      </button>
      <button [disabled]="!checked || busy" type="button" class="ex-btn validate-btn"
              [class.ex-btn-strong]="updateMode" [class.ex-btn-danger]="deleteMode"
              (click)="confirm()"
      >
        @if (busy) {
          <i class="ph ph-spinner animate-spin fs-5"></i>
        } @else {
          <span *ngIf="updateMode" class="text-uppercase fw-bold align-self-center">
          {{translationFileSection + '.modal-edit-save' | translate}}
        </span>
          <span *ngIf="deleteMode" class="text-uppercase fw-bold align-self-center">
          {{translationFileSection + '.modal-delete' | translate}}
        </span>
        }
      </button>
    </div>
  } @else {
    <app-simple-loader vertical class="m-2"/>
  }
</div>
