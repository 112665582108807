import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Tender } from '../../models/tender';
import { TenderTypesEnum } from '../../models/tender-search-body';
import { SharedDocument } from '../../../models/share/share';
import { DocumentSharingTypeEnum } from '../../../models/document-entity';
import { TendersModuleService } from '../../services/tenders-module.service';
import { ExplainModuleEnum } from "../../../shared/services/module-manager.service";
import Quill from "quill";
import Delta from "quill-delta";
import { convertTenderToDeltas, getNoteDelta } from "./convert-tender-to-deltas";
import { TranslateService } from "@ngx-translate/core";
import { QuillEditorComponent } from "ngx-quill";
import { GridQuestion } from "../../models/grid-question";
import { SmartGridService } from "../../services/smart-grid.service";
import { debounceTime, first, map, throttleTime } from "rxjs/operators";
import { firstValueFrom } from "rxjs";
import {
  ModalConfirmationSimple
} from "../../../components/modal-confirmation-simple/modal-confirmation-simple.component";

interface Displays {
  note: boolean;
  summary: boolean;
  pinned_questions: boolean;
  [key: string]: boolean;
}
const MAX_LENGTH = 30000;
const INIT_GENERATION_TIME = 15000;
const SHORT_WAITING_TIME = 750;

export enum AnswerPlaceholderTextEnum {
  NOT_FOUND = 'Information non trouvée',
  TOO_OLD = 'Le marché est trop ancien pour être analysé'
}

@Component({
  selector: 'app-modal-share-watch',
  templateUrl: './modal-share-watch.component.html',
  styleUrls: ['./modal-share-watch.component.scss']
})
export class ModalShareWatchComponent implements OnInit, OnDestroy {
  protected readonly ExplainModuleEnum = ExplainModuleEnum;
  public TenderTypesEnum = TenderTypesEnum;
  @ViewChild(QuillEditorComponent, {static: false}) editor!: QuillEditorComponent;
  @ViewChild('tenderMetadata', {read: TemplateRef}) tenderMetadata!: TemplateRef<any>;
  @Input() tender!: Tender;
  @Input() inquirers!: string;
  @Input() department!: string;
  @Input() note!: string;
  @Input() smartGridService!: SmartGridService;

  displays: Displays = {note: true, summary: true, pinned_questions: true};
  showLoader = false;
  document!: SharedDocument;
  emailShare: boolean = false;

  // Section relative to Quill Editor
  quillInstance!: Quill;
  content = '';
  initialQuillContent!: Promise<Delta>
  htmlContent: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'],
    ]
  };
  MAX_LENGTH = MAX_LENGTH;
  charCount = 0;
  maxLengthReached = false;
  userChangeHasOccurred = false;
  areChunksDeindexed = false;

  constructor(
    private activeModal: NgbActiveModal,
    private tendersModuleService: TendersModuleService,
    private translate: TranslateService,
    private modaleService: NgbModal,
  ) { }

  async ngOnInit() {
    const currentUser = await this.tendersModuleService.user;
    const companyUsers = await this.tendersModuleService.groupAccountUsers;
    const assignedUser = companyUsers.concat(currentUser).find((user) => user.id === this.tender.assignedUserId)?.name
    // due to the way modaleShareWatch is instanced, no need to user ngOnChanges because all data il
    // immediately available on init.
    this.document = new SharedDocument({
      document_uid: this.tender.id,
      type: DocumentSharingTypeEnum.TENDER_DOCUMENT,
      title: this.tender.title,
      indexation_date: this.tender.indexationDate,
      closing_date: this.tender.closingDate,
      territories: this.tender.territories,
      inquirers: this.tender.inquirers,
      tender_type: this.tender.tenderType,
      market_type: this.tender.marketType?.join(','),
      note: this.tender.note,
      status: this.tendersModuleService.statusItems.find((item) => item.value.id === this.tender.statusId)?.value.name,
      assigned_user: assignedUser,
      dce_project_uid: this.tender.dceProjectUid,
      dce_url: this.tender.dceUrl
    })

    this.initialQuillContent = convertTenderToDeltas(
      this.tender,
      this.tendersModuleService.statusItems,
      this.document,
      this.inquirers,
      this.department,
      this.translate
    );

    const stringDisplays = localStorage.getItem('share-smart-grid-display-settings');
    if (stringDisplays) this.displays = (JSON.parse(stringDisplays) as Displays) ?? this.displays;
  }

  ngOnDestroy() {
    localStorage.setItem('share-smart-grid-display-settings', JSON.stringify(this.displays));
  }

  trad = async (string: string) => {
    return firstValueFrom(this.translate.get(`tenders.market-watches.modal-share-watch.${string}`));
  };

  /** A la création du composant, on initialise son contenu */
  async onEditorCreated(quill: Quill) {
    this.quillInstance = quill;
    this.quillInstance.on('text-change', () => {
      this.charCount = quill.getLength() - 1; // -1 pour exclure le dernier caractère invisible
      if (this.charCount > this.MAX_LENGTH) {
        this.maxLengthReached = true;
        quill.deleteText(this.MAX_LENGTH, this.charCount); // Supprime l'excédent
        setTimeout(() => this.maxLengthReached = false, 1000); // Reset après 1s
      }
    });
    let quillContent = await this.initialQuillContent;

    if (this.displays.note) quillContent = new Delta({ ops : quillContent.ops.concat((await getNoteDelta(this.tender, this.translate)).ops) })
    if (!this.displays.summary && !this.displays.pinned_questions) {
      this.showLoader = true;
      this.quillInstance.setContents(new Delta());
      await new Promise(() => setTimeout(() => {this.quillInstance.setContents(quillContent);this.showLoader=false;}, SHORT_WAITING_TIME))
    }
    this.quillInstance.setContents(quillContent);

    // STOP pas de DCE, donc pas de données de la grille !
    if (!this.tender.dceProjectUid) return;
    // STOP pas ni résumé, ni questions épinglées à afficher
    if (!this.displays.summary && !this.displays.pinned_questions) return;
    this.showLoader = true;
    const timeoutPromise = new Promise((resolve) => setTimeout(() => resolve('timeout'), INIT_GENERATION_TIME));

    // RESUME
    if (this.displays.summary) {
      const summary = await firstValueFrom(this.smartGridService._summaryQuestion$
        .pipe(
          debounceTime(750),
          throttleTime(500, undefined, {leading: false, trailing: true}),
          first()
        )
        .pipe(map((value) => {
          return value?.data ?? {questionId: -1}
        })))
      this.appendQuestionTitle(await this.trad('summary'));
      this.insertPinnedQuestion({...summary, displayedName: null}, 'summary');
    }

    // CRITÈRES ÉPINGLÉS
    if (this.displays.pinned_questions) {
      const pinnedQuestions = await firstValueFrom(this.smartGridService._pinnedQuestion$
        .pipe(
          debounceTime(750),
          throttleTime(500, undefined, {leading: false, trailing: true}),
          first()
        ));
      if (pinnedQuestions?.data.length) this.appendQuestionTitle(await this.trad('pinned-questions'));
      pinnedQuestions?.data.map((question: GridQuestion) => {
        this.insertPinnedQuestion(question, 'pinned-question');
      })
    }

    await Promise.race([this.smartGridService.allAnswersPromise, timeoutPromise])
    this.showLoader = false;
    // final paste to make pinned-questions paragraphs editable
    this.smartGridService.allAnswersPromise.then(() => this.quillInstance.clipboard.dangerouslyPasteHTML(this.mutateEmbedsToText()));
  }

  insertPinnedQuestion(gridQuestion: {questionId: number, displayedName: string | null}, embedType: 'pinned-question' | 'summary') {
    const promise = this.smartGridService.promiseAnswersMap.get(gridQuestion?.questionId);
    if (gridQuestion.displayedName) this.quillInstance.insertText(this.quillInstance.getLength(), '\n' + gridQuestion.displayedName + '\n', {bold: true});
    this.quillInstance.insertEmbed(this.quillInstance.getLength(), 'pinned-question', {
      promise,
      text: 'récupération en cours...',
      embedType
    });
  }

  appendQuestionTitle(text: string) {
    const pinnedQuestionTitle = [{retain: this.quillInstance.getLength()},{ insert: '\n' + text + '\n', attributes: { header: 4, bold: true } }]
    this.quillInstance.updateContents(pinnedQuestionTitle);
  }

  onContentChanged(event: {source: string}) {
    if (event.source === 'api') this.mutateEmbedsToText();
    if (event.source === 'user') this.userChangeHasOccurred = true;
    this.htmlContent = this.quillInstance.getSemanticHTML(0, this.quillInstance.getLength());
  }

  async toggleCheckbox(attribute: keyof Displays, event: any) {
    if (this.userChangeHasOccurred) {
      event.preventDefault();
      event.stopPropagation();
      const warningModale = this.modaleService.open(ModalConfirmationSimple, {centered: true})
      warningModale.componentInstance.title = await this.trad('validation-modale.title');
      warningModale.componentInstance.content = await this.trad('validation-modale.content');
      warningModale.componentInstance.validate = await this.trad('validation-modale.confirm');
      warningModale.componentInstance.onValidateAction = async () => {
        this.displays[attribute] = !this.displays[attribute];
        this.userChangeHasOccurred = false;
        await this.onEditorCreated(this.quillInstance);
      };
    } else {
      this.displays[attribute] = !this.displays[attribute];
      this.userChangeHasOccurred = false;
      await this.onEditorCreated(this.quillInstance);
    }
  }


  async onCopy() {
    // Copy to clipboard
    await navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([this.quillInstance.getSemanticHTML(0, this.quillInstance.getLength())], {type: "text/html"}),
        "text/plain": new Blob([this.quillInstance.getText(0, this.quillInstance.getLength())], {type: "text/plain"})
      })
    ]).then(() => {
      this.activeModal.close('success');
    }).catch(() => {
      this.activeModal.close('error');
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }

  getTextFromNode(node: Node): string {
    let text = '';
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      node.childNodes.forEach(childNode => {
        text += this.getTextFromNode(childNode);
        text += '\n'
      });
    }
    return text.trim();
  }

  mutateEmbedsToText() {
    const htmlContent = this.quillInstance.container.innerHTML;
    // 1. Parse le HTML en DOM
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    // WIP ici pour conserver cette classe.
    // 2. Supprime toutes les classes sauf celles ciblées pour modification
    doc.body.querySelectorAll('[class]').forEach(async el => {
      if (el.classList.contains('embed-pinned-question') && !el.innerHTML.includes('récupération en cours...')) {
        el.classList.replace('embed-pinned-question', 'pinned-question'); // Remplace l'ancienne classe par la nouvelle
      }
      if (el.innerHTML === 'Information non trouvée') {
        const noResponse = document.createElement('em');
        noResponse.innerHTML = el.innerHTML;
        el.parentNode?.replaceChild(noResponse, el);
      }
      if (el.innerHTML === AnswerPlaceholderTextEnum.TOO_OLD) {
        // on reset le wysiwyg avec les informations de base.
        this.areChunksDeindexed = true;
        this.quillInstance.setContents(await this.initialQuillContent);
      }
    });
    // 3. Remplace &nbsp; par un espace normal
    doc.body.innerHTML = doc.body.innerHTML.replace(/&nbsp;/g, ' ').replace(/\[[^\[\]]{6,}\]/g, '');
    // 4. Retourne le HTML nettoyé
    return doc.body.innerHTML;
  }

}
