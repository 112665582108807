<div class="new-item-modal">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ title }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()">
    </button>
  </div>
  <div class="modal-body extra-large">
    <div class="modal-sub-title">
      {{ content }}
    </div>
  </div>

  <div class="modal-footer justify-content-end">

    <button class="ex-btn-optional-transparent ex-btn"
            (click)="dismissModal()"
    >
      {{ cancel }}
    </button>
    <button type="button" class="ex-btn-strong ex-btn"
            (click)="confirm()"
    >
      <span
        class="fw-bold align-self-center">{{ validate }}</span>
    </button>
  </div>
</div>
