<ng-template #countLoader let-item=item>
  <span *ngIf="countOf(item) !== undefined">({{countOf(item)}})</span>
  <span *ngIf="countOf(item) === undefined" class="mini-spinning-loader">
    <i class="ph ph-circle-notch"></i>
  </span>
</ng-template>

<div>
  <div class="chips d-flex">
    <ng-container *ngFor="let item of selectedItems">
      <ex-chip [backgroundColor]="item.value.backgroundColor ?? computeStatusBackground(item)"
               [color]="item.value.color ?? ''"
               (closeClick)="removeStatus(item)"
               class="text-span-container text-basic no-select">
        <span class="text-span">{{item.label}}</span><ng-container *ngTemplateOutlet="countLoader; context: {item: item}"></ng-container>
      </ex-chip>
    </ng-container>

    <div *ngIf="availableDefaultItems.length + availableCustomItems.length" class="chips-dropdown d-flex">
      <ex-dropdown noInput noChevron noBorder headerBackgroundColor="transparent"
                   container="body"
                   [items]="availableItems">
        <ng-template *ngIf="selectedItems.length !== 0" exDropdownDisplay>
          <div class="chevron-btn">
            <i class="ph-bold ph-caret-down"></i>
          </div>
        </ng-template>
        <ng-template *ngIf="selectedItems.length === 0" exDropdownDisplay>
          <div class="marine-btn">
            <div class="marine-text">
              {{ 'Statut '}}
            </div>
            <i class="ph-bold ph-plus"></i>
          </div>
        </ng-template>
        <ng-template exDropdownContent>
          <app-tender-status-list [defaultItems]="availableDefaultItems" [customItems]="availableCustomItems"
                                  (itemSelect)="addStatus($event)" [tendersSearchService]="tendersSearchService"
                                  withCounter
          />
        </ng-template>
      </ex-dropdown>

    </div>
  </div>
</div>
