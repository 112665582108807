@if (!territories?.length) {
  <span class="color-basic-600 fst-italic">{{ "tenders.not-detected-m" | translate }}</span>
  {{territories}}
} @else {
  @if (territories.length < maximumShownTerritories) {
    <span [ngbTooltip]="tenderTerritoriesTextService.getString(territories)"
          tooltipClass="tender-territories-tooltip"
          placement="bottom auto"
          [openDelay]="500"
          overflowTooltip
    >
      {{ tenderTerritoriesTextService.getCompactCodeString(territories) }}
    </span>
  } @else {
    <span [ngbTooltip]="tenderTerritoriesTextService.getString(territories)"
          tooltipClass="tender-territories-tooltip"
          placement="bottom auto"
          [openDelay]="500"
    >
      ({{ tenderTerritoriesTextService.getCompactString(territories, reducedShownTerritories, true) }})
    </span>
  }
}
