@if (amount) {
  @if (amount.total) {
    {{ amount.total | currency:'EUR':'symbol':'.0':'fr' }}
  } @else if (amount.min) {
    {{ amount.min | currency:'EUR':'symbol':'.0':'fr' }} (min.)
    {{ amount.max ? ' - ' + (amount.max | currency:'EUR':'symbol':'.0':'fr') + ' (max.)' : '' }}
  } @else if (amount.max) {
    {{ amount.max | currency:'EUR':'symbol':'.0':'fr' }} (max.)
  }
} @else {
  {{ 'tenders.' + undetectedTranslationKey | translate }}
}
