import { Period } from "../../tenders/services/tenders-search.service";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {MarketWatchDayOfWeek} from "../../tenders/models/market-watch";

const days = require('./days.json') as MarketWatchDayOfWeek[];

export function isSameDay(date1: Date, date2: Date) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function isSamePeriod(period1: Period | null, period2: Period | null) {
  if (!period1 || !period2) return false;
  return isSameDay(new Date(period1.from), new Date(period2.from)) && isSameDay(new Date(period1.to), new Date(period2.to));
}


export function toDayPeriod(toDate: Date | string, fromDate?: Date | string): Period {
  // Return an indexation period to provide to the filters
  const to = new Date(toDate);
  to.setUTCHours(5, 0, 0, 0)
  let from;
  if (fromDate) {
    // Set a custom period between two dates
    from = new Date(fromDate);
  } else {
    // No 'past' bound provided : set the default 1-day period
    from = new Date(toDate);
    from.setDate(to.getDate() - 1);
  }
  from.setUTCHours(5, 0, 0, 0);
  return {
    from: from.toISOString(),
    to: to.toISOString()
  }
}

export function findPeriod(date: Date, daysOfWeek: string[] | undefined, marketDates?: string[]) {
  if (!marketDates) {
    // No marketDates provided : we are in editMode, just return the basic 1-day period.
    return toDayPeriod(date);
  }
  // Convert marketDates strings to Date objects
  const marketDatesParsed = marketDates.map(d => new Date(d));
  // Check if the date is among the market watch dates
  const indexOfCurrentDate = marketDatesParsed.findIndex(d => isSameDay(d, date));
  const theoricPreviousDate = findTheoricClosestWatchDate(date, daysOfWeek, false)
  if (indexOfCurrentDate !== -1) {
    // If the date is found in marketDates
    const previousDate = indexOfCurrentDate === 0 ? theoricPreviousDate : marketDates[indexOfCurrentDate - 1];
    return toDayPeriod(marketDates[indexOfCurrentDate], previousDate);
  }
  // If the date is before the first market watch date
  if (date < marketDatesParsed[0]) {
    return toDayPeriod(marketDates[0], theoricPreviousDate);
  }
  // If the date is after the last market watch date
  if (date > marketDatesParsed[marketDatesParsed.length - 1]) {
    return toDayPeriod(marketDates[marketDatesParsed.length - 1], marketDates[marketDatesParsed.length - 2]);
  }
  // If the date is in between market watch dates
  return toDayPeriod(date);
}


export function findTheoricClosestWatchDate(date: Date, daysOfWeek: string[] | undefined, goForward: boolean) {
// Garder seulement les jours qui sont dans la frequency actuelle <=> dans daysOfWeek
  const daysOfWeekDict = days.filter(day => daysOfWeek?.includes(day.value));

  // Calculer l'écart minimal entre la date et les jours de veille, pour trouver quel week day est le plus proche (en avant ou en arrière)
  const ecarts = daysOfWeekDict.map(day => {
    const ec = (goForward ? day.index - date.getDay() : date.getDay() - day.index) % 7;
    return ec > 0 ? ec : ec + 7
  });
  const closest = Math.min(...ecarts);

  // Modifier la date actuelle pour la décaler au weekday trouvé
  const closestDate = new Date(date);
  closestDate.setDate(date.getDate() + (goForward ? closest : -closest));
  return closestDate;
}


export function ngbDateToDate(date: NgbDate) {
  // S'assurer que la date passée soit sous le format "YYYY-MM-DD" et pas "YYYY-M-D", pour conserver le fuseau horaire
  return new Date(`${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`)
}

export function stringToNgbDate(date: string) {
  // String has to be of the form 'YYYY-MM-DD'
  const segments = date.split('-')
  return new NgbDate(+segments[0], +segments[1], +segments[2]);
}

export function formatDateAsYYYYMMDD(input: string | Date): string {
  const date = (typeof input === 'string') ? new Date(input) : input;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois sont indexés à partir de 0
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function hasNoTime(date: Date) {
  return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
}

export function getDateWithoutTime(date: Date) {
  return date.setHours(0,0,0,0);
}
