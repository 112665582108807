import { Component, Input} from '@angular/core';

const config = {
  light : {
    backgroundColor: 'var(--basic-250)',
    color: 'var(--basic-600)',
    borderColor: '10%'
  },
  dark : {
    backgroundColor: 'var(--basic-400)',
    color: 'var(--basic-700)',
    borderColor: 'var(--basic-450)'
  }
}

@Component({
  selector: 'app-tender-type-badge',
  templateUrl: './tender-type-badge.component.html',
  styleUrls: ['./tender-type-badge.component.scss']
})
export class TenderTypeBadgeComponent {
  protected readonly config = config;

  @Input() style: 'light' | 'dark' = 'light';
  @Input({required: true}) tenderType!: string;
}
