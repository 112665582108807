import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiTenderService } from '../../services/api/api-tender.service';
import { firstValueFrom } from 'rxjs';
import { DbTenderDetail, TenderDetail } from '../../models/tender-detail';
import { GridQuestion, GridQuestionFormActionEnum, GridQuestionSectionEnum } from '../../models/grid-question';
import { GridAnswer } from '../../models/grid-answer';
import { TenderEntityService } from '../../services/tender-entity.service';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { EventTypeName } from '../../../models/user-tracker';
import { AutoUnsubscribe } from '../../../common-explain/decorators/auto-unsubscribe';
import { DbTenderAnnotation } from '../../services/api/annotation/api-annotation.service';
import { Tender } from '../../models/tender';
import { TendersModuleService } from '../../services/tenders-module.service';
import { StatusItem } from '../../models/tender-status';
import { ChunkDetail } from '../../models/chunk-detail';
import { SmartGridService } from '../../services/smart-grid.service';
import { TenderSmartGridComponent } from '../tender-smart-grid/tender-smart-grid.component';

@Component({
  selector: 'app-tender-detail',
  templateUrl: './tender-detail.component.html',
  styleUrls: ['./tender-detail.component.scss'],
  providers: [SmartGridService]
})
@AutoUnsubscribe
export class TenderDetailComponent implements OnInit, OnDestroy {
  protected readonly JSON = JSON;

  tenderId!: string;
  tenderDetail!: TenderDetail;
  dbTenderAnnotation?: DbTenderAnnotation;

  clickedAnswer: GridAnswer | null = null;
  clickedRef: ChunkDetail | null = null;

  @ViewChild('iframeNewQuestion') iframeNewQuestion?: ElementRef;
  @ViewChild(TenderSmartGridComponent) tenderSmartGrid!: TenderSmartGridComponent;

  isQuestionFormOpened = false;
  questionFormAction: GridQuestionFormActionEnum = GridQuestionFormActionEnum.ADD;
  questionToEdit: GridQuestion | null = null;
  mode: 'edition' | 'view' = 'view';

  constructor(private route: ActivatedRoute,
              protected apiTenderService: ApiTenderService,
              private tenderEntityService: TenderEntityService,
              private userTrackerService: UserTrackerService,
              private tendersModuleService: TendersModuleService,
              protected smartGridService: SmartGridService
  ) {
  }

  async ngOnInit() {
    const route = this.route.snapshot;
    this.tenderId = route.params['tenderId'];
    this.tenderEntityService.tenderId$.next(this.tenderId);
    const dbTenderDetail = await this.apiTenderService.tenderDetail
      .getTenderDetail(this.tenderId, route.queryParams['from'])
      .catch(() => {
        return undefined;
      });
    if (!dbTenderDetail) {
      return;
    }
    this.dbTenderAnnotation = await this.apiTenderService.annotation.getAnnotation(this.tenderId)
      .catch(() => {
        return undefined;
      });
    dbTenderDetail.tender_annotations_id = this.dbTenderAnnotation?.id;
    dbTenderDetail.status_id = this.dbTenderAnnotation?.status_id;
    dbTenderDetail.note = this.dbTenderAnnotation?.note;
    dbTenderDetail.assigned_user_id = this.dbTenderAnnotation?.assigned_user_id;
    this.tenderDetail = new TenderDetail(dbTenderDetail);

    if (this.tenderDetail.dceProjectUid) {
      await this.smartGridService.setProjectUid(this.tenderDetail.dceProjectUid);
    }
  }

  openAddPrivateQuestionForm() {
    this.questionToEdit = null;
    this.isQuestionFormOpened = true;
    this.questionFormAction = GridQuestionFormActionEnum.ADD;
  }

  openEditPrivateQuestionForm(question: GridQuestion) {
    this.questionToEdit = question;
    this.isQuestionFormOpened = true;
    this.questionFormAction = GridQuestionFormActionEnum.EDIT;
  }

  async handleEditQuestion(questionId: number) {
    this.isQuestionFormOpened = false;
    const question = await this.smartGridService.fetchQuestion(questionId);
    await this.tenderSmartGrid.onClickQuestion(question, true);
  }

  async handleAddQuestion() {
    this.isQuestionFormOpened = false;
    const unpinnedQuestionsIds = this.smartGridService.unpinnedQuestions.map(q => q.questionId);
    await this.smartGridService.fetchQuestions(undefined, GridQuestionSectionEnum.UNPINNED);
    const newUnpinnedQuestion = this.smartGridService.unpinnedQuestions.find(q => !unpinnedQuestionsIds.includes(q.questionId));
    if (newUnpinnedQuestion) {
      await this.tenderSmartGrid.onClickQuestion(newUnpinnedQuestion, true);
    }

  }

  async handleDeleteQuestion(questionId: number) {
    this.isQuestionFormOpened = false;
    const section = this.smartGridService.pinnedQuestions.find(q => q.questionId === questionId) ? GridQuestionSectionEnum.PINNED : GridQuestionSectionEnum.UNPINNED;
    if (section === GridQuestionSectionEnum.PINNED) {
      this.smartGridService.pinnedQuestions = this.smartGridService.pinnedQuestions.filter(q => q.questionId !== questionId);
    } else {
      this.smartGridService.unpinnedQuestions = this.smartGridService.unpinnedQuestions.filter(q => q.questionId !== questionId);
    }
  }

  handleFormClosed() {
    this.isQuestionFormOpened = false;
  }

  ngOnDestroy() {
    this.tenderEntityService.tenderId$.next('');
  }

  trackEvent(data = {}, eventName = '') {
    firstValueFrom(this.userTrackerService.track({
      event_type: eventName,
      event_timestamp: (new Date()).toISOString(),
      ...this.userTrackerService.buildBasicUserInformations(),
      tender_id: this.tenderDetail.id,
      ...data
    }));
  }

  onScrollEnd() {
    this.trackEvent({is_question_selected: this.clickedAnswer !== null, question: this.clickedAnswer},
      EventTypeName.TENDER_GRID_SCROLLED);
  }

  noteChange(tender: Tender, note: string) {
    tender.note = note;
    // propagate note change to all services
    this.tendersModuleService.updateTenderInRegisteredServices(this.tenderId, {note});
  }

  statusChange(statusId: number | undefined, event: StatusItem) {
    const boardService = this.tendersModuleService.boardSearchService;
    if (this.tendersModuleService.boardSearchService) {
      if (this.tenderDetail.statusId && event === undefined) { // cas remove status.
        boardService.statusCounts?.length && boardService.updateStatusCount({from: this.tenderDetail.statusId, to: null});
      } else { // sinon mise à jour des compteurs de la vue board.
        boardService.statusCounts?.length && boardService.updateStatusCount({from: statusId ?? null, to: event.id});
      }
    }
    // mise à jour de l'instance locale.
    this.tenderDetail.statusId = event.id!;
    // propagate status change to all services.
    this.tendersModuleService.updateTenderInRegisteredServices(this.tenderId, {statusId: event.id ?? undefined});
  }

  onUpdateTender(updatedTender: Tender) {
    this.tenderDetail.note = updatedTender.note;
    this.tenderDetail.statusId = updatedTender.statusId;
    this.tenderDetail.assignedUserId = updatedTender.assignedUserId;
    this.tenderDetail.tenderAnnotationsId = updatedTender.tenderAnnotationsId;
  }
}
