import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpHeadersService } from '../http-headers/http-headers.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiGroupAccountStatusService {

  constructor(private httpHeadersService: HttpHeadersService,
              private http: HttpClient) { }

  retrieveGroupAccountStatuses(body: any): Promise<{data: Array<{ id: number, status_name: string, displayed_status_name: string }> }> {
    return firstValueFrom(this.http
      .post<{data: Array<{ id: number, status_name: string, displayed_status_name: string }> }>(
        `${environment.explainApiUrl}group_account_statuses`,
        JSON.stringify(body),
        this.httpHeadersService.httpHeaders
      )
    );
  }

  createGroupAccountStatus(body: {displayedStatusName: string}) {
    return firstValueFrom(this.http
      .put<{ group_account_status_id: number, message: string }>(
        `${environment.explainApiUrl}group_account_statuses`,
        {
          displayed_status_name: body.displayedStatusName,
        },
        this.httpHeadersService.httpHeaders
      )
    );
  }

  updateGroupAccountStatus(body: {id: number, displayedStatusName: string}) {
    return firstValueFrom(this.http
      .patch<{ id: number, message: string }>(
        `${environment.explainApiUrl}group_account_statuses`,
        {
          id: body.id,
          displayed_status_name: body.displayedStatusName,
        },
        this.httpHeadersService.httpHeaders
      )
    );
  }

  deleteGroupAccountStatus(id: number) {
    return firstValueFrom(this.http
      .delete<{ message: string }>(
        `${environment.explainApiUrl}group_account_statuses?id=${id}`,
        this.httpHeadersService.httpHeaders,
      )
    );
  }
}
