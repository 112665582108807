import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirmation-with-checkbox',
  templateUrl: './modal-confirmation-with-checkbox.html',
  styleUrls: ['./modal-confirmation-with-checkbox.scss']
})
export class ModalConfirmationWithCheckbox {
  @Input() translationFileSection!: string;
  @Input() updateMode!: boolean;
  @Input() deleteMode!: boolean;
  @Input() onValidateAction!: () => void | Promise<void>;
  @Input() totalItemsCount?: number;
  @Input() affectedItemsCount!: number;
  @Input() targetValue?: string;
  @Input() ready: boolean = true;

  busy: boolean = false;
  checked = false;

  constructor(
    private activeModal: NgbActiveModal,
  ) {

  }

  onCheckedChange() {
    this.checked = !this.checked;
  }

  dismissModal() {
    this.activeModal.dismiss();
  }


  async confirm() {
    if (this.checked) {
      this.busy = true;
      await this.onValidateAction();
      this.busy = false;
      this.activeModal.close(true);
    }
  }

}
