import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  FilteringPeriodTypeEnum,
  Period,
} from "../../../tenders/services/tenders-search.service";
import { DbPeriod } from "../../../tenders/components/filter-date/filter-date.component";
import { ExDateSelectorComponent } from "../ex-date-selector/ex-date-selector.component";
import { formatDateAsYYYYMMDD } from "../../../shared/helpers/date-helper";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'ex-period-selector',
  standalone: true,
  imports: [
    ExDateSelectorComponent,
    TranslateModule
  ],
  templateUrl: './ex-period-selector.component.html',
  styleUrl: './ex-period-selector.component.scss'
})
export class ExPeriodSelectorComponent {
  @ViewChild('firstDateSelector') firstDateSelector?: ElementRef;
  @ViewChild('secondDateSelector') secondDateSelector?: ElementRef;

  @Input() defaultPeriod?: DbPeriod;
  @Input({required: true}) periodField!: FilteringPeriodTypeEnum;
  @Input() allowedDateRange? : {min: NgbDate, max: NgbDate};

  @Output() periodSelected = new EventEmitter<{ period: Period | undefined, field: FilteringPeriodTypeEnum }>();

  // On déclenche une mise à jour du période lorsqu'on affiche le composant
  @Input() set hidden(value: boolean) {this.templateRef.nativeElement.hidden = value; this.updatePeriod(!value);}

  period!: Period;
  dbPeriod!: DbPeriod;
  today = new Date();

  constructor(private templateRef: ElementRef) {
    this.period = {
      from: formatDateAsYYYYMMDD(this.today),
      to: formatDateAsYYYYMMDD(this.today)
    }
    this.dbPeriod = this.defaultPeriod ?? {
      from: this.today,
      to: this.today
    };
  }

  onDateSelection(date: Date | null, tip: 'from' | 'to') {
    // Stop updating period is no change in date day.
    if (this.isSameDay(this.dbPeriod[tip], date)) return;
    if (date) {
      this.period[tip] = formatDateAsYYYYMMDD(date);
      this.dbPeriod[tip] = date;
    }
    this.updatePeriod();
  }

  updatePeriod(propagate = true) {
    if (!propagate) return;
    if (!(this.dbPeriod.from && this.dbPeriod.to)) return;
    if (this.periodNotValid) return;
    this.periodSelected.emit({
      period: this.period,
      field: this.periodField
    });
  }

  isSameDay(date1: Date | null, date2: Date | null) {
    return (date1 && date2) && date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  }

  get periodNotValid() {
    const toRet = this.dbPeriod.from && this.dbPeriod.to && ((this.dbPeriod.from > this.dbPeriod.to) || (this.dbPeriod.to < this.dbPeriod.from));
    return toRet ?? true;
  }

  onTabKeyFirstSelector(event: any) {
    if (!event.shiftKey && this.secondDateSelector) {
      event.preventDefault();
      this.secondDateSelector.nativeElement.querySelector('input[type="date"]').focus();
    } else {
      event.preventDefault();
    }
  }

  onTabKeySecondSelector(event: any) {
    if (event.shiftKey && this.firstDateSelector) {
      event.preventDefault();
      this.firstDateSelector.nativeElement.querySelector('input[type="date"]').focus();
    } else {
      event.preventDefault();
    }
  }

}
