<div class="filter-date-container d-flex align-items-center"
[ngClass]="{'pdl-8px': displayedEstimatedEndPeriod}">
  <div class="d-flex align-items-center">
    @if (displayedEstimatedEndPeriod) {
      <div class="date-filter-mode-selector d-flex">
        <button class="btn-selection"
                [ngClass]="{'selected': filteringPeriodType === FilteringPeriodTypeEnum.INDEXATION}"
                (click)="setDatePeriodMode(FilteringPeriodTypeEnum.INDEXATION, $event)">
          <span>{{ 'tenders.filter.' + FilteringPeriodTypeEnum.INDEXATION | translate }}</span>
        </button>
        @if (allowSearchByEstimatedEndPeriod) {
          <button class="btn-selection"
                  [ngClass]="{'selected': filteringPeriodType === FilteringPeriodTypeEnum.ESTIMATED_END}"
                  (click)="setDatePeriodMode(FilteringPeriodTypeEnum.ESTIMATED_END, $event)">
            <span>{{ 'tenders.filter.' + FilteringPeriodTypeEnum.ESTIMATED_END | translate }}</span>
          </button>
        } @else {
          <button class="btn-selection" disabled
                  longHoverOnElement (longHoverTriggered)="managePastille()"
                  [ngbTooltip]="soonTooltip"
                  placement="bottom left"
                  tooltipClass="custom-tooltip-soon"
                  [ngClass]="{'selected': filteringPeriodType === FilteringPeriodTypeEnum.ESTIMATED_END,
                            'pastille': showPastille}"
                  (click)="setDatePeriodMode(FilteringPeriodTypeEnum.ESTIMATED_END, $event)">
            <span>{{ 'tenders.filter.' + FilteringPeriodTypeEnum.ESTIMATED_END | translate }}</span>
          </button>
        }
      </div>
    }
  </div>

  <div class="d-flex align-items-center position-relative">
    <ex-period-selector
      [hidden]="filteringPeriodType === FilteringPeriodTypeEnum.ESTIMATED_END"
      [defaultPeriod]="defaultPeriod?.[FilteringPeriodTypeEnum.INDEXATION]"
      [periodField]="FilteringPeriodTypeEnum.INDEXATION"
      (periodSelected)="periodSelected.emit($event)"
      [allowedDateRange]="ALLOWED_DATE_RANGE[FilteringPeriodTypeEnum.INDEXATION]"/>
    @if (displayedEstimatedEndPeriod) {
      <ex-period-selector
      [hidden]="filteringPeriodType === FilteringPeriodTypeEnum.INDEXATION"
      [defaultPeriod]="defaultPeriod?.[FilteringPeriodTypeEnum.ESTIMATED_END]"
      [periodField]="FilteringPeriodTypeEnum.ESTIMATED_END"
      (periodSelected)="periodSelected.emit($event)"
      [allowedDateRange]="ALLOWED_DATE_RANGE[FilteringPeriodTypeEnum.ESTIMATED_END]"/>
    }
  </div>

  <div class="icon-calendar">
    <i class="ph ph-calendar"></i>
  </div>

</div>


<ng-template #soonTooltip>
  <div class="ph-text-align-left">
    <span class="fw-600">Bientôt disponible</span>
  </div>
  <div class="ph-text-align-left">
    <span>Détectez vos prochains marchés en filtrant par date de fin de marché</span>
  </div>
</ng-template>
