import { Component, effect, OnDestroy, OnInit } from '@angular/core';
import { TenderEntityService } from '../../services/tender-entity.service';
import { FilteringPeriodTypeEnum, Period, TendersSearchService } from '../../services/tenders-search.service';
import { ApiTenderService } from '../../services/api/api-tender.service';
import { FilterType } from '../tenders-filter/tenders-filter.component';
import { TendersModuleService } from '../../services/tenders-module.service';
import { DisplayItem } from '../../../common-explain/components/ex-dropdown/ex-dropdown.component';
import {
  ApiSearchServiceEnum,
  DbPostSearchSort,
  PostTenderSearchBody,
  SortFieldEnum,
  TenderTypesEnum
} from '../../models/tender-search-body';
import { SortDirEnum } from '../../../common-explain/models/sort';
import { StatusCountItem, StatusItem } from '../../models/tender-status';
import { debounceTime, filter, throttleTime } from "rxjs/operators";
import { AutoUnsubscribe } from "../../../common-explain/decorators/auto-unsubscribe";
import { TendersActionService } from '../../services/tenders-action.service';
import { UserSettingsService } from '../../../common-explain/services/user-settings.service';
import { FilteringPeriod } from "../tenders-search/tenders-search.component";
import { BrowserDetectorService } from "../../../common-explain/services/browser-detector.service";

const COUNT_DEFAULT_SORT: DbPostSearchSort = {dir: SortDirEnum.ASC, field: SortFieldEnum.CLOSING_DATE}

@Component({
  selector: 'app-tenders-board',
  templateUrl: './tenders-board.component.html',
  styleUrls: ['./tenders-board.component.scss'],
  providers: [TendersActionService]
})
@AutoUnsubscribe
export class TendersBoardComponent implements OnInit, OnDestroy {
  protected readonly FilterType = FilterType;

  tendersSearchService: TendersSearchService

  defaultPeriod: FilteringPeriod;
  showFilters = false;
  status: DisplayItem<StatusItem>[] = [];
  statusCountsRequest?: Promise<any>;


  constructor(
    protected tenderEntityService: TenderEntityService,
    protected browser: BrowserDetectorService,
    private apiTenderService: ApiTenderService,
    private tendersModuleService: TendersModuleService,
    protected tendersExportService: TendersActionService,
    private userSettingsService: UserSettingsService,
  ) {
    this.tendersSearchService = new TendersSearchService(apiTenderService);
    this.tendersSearchService.sort = {field: SortFieldEnum.CLOSING_DATE, dir: SortDirEnum.ASC};
    let from = new Date();
    from.setMonth(from.getMonth() - 3);
    this.defaultPeriod = {
      [FilteringPeriodTypeEnum.INDEXATION]: {
        from: from,
        to: new Date()
      }
    };
    const storedTerritoriesFilter = this.userSettingsService.get('board-territories-filter');
    if (storedTerritoriesFilter) this.tendersSearchService.selectedTerritories = storedTerritoriesFilter;
    const storedTypesFilter = this.userSettingsService.get('board-types-filter');
    if (storedTypesFilter) this.tendersSearchService.tenderTypes = storedTypesFilter;
    this.tendersModuleService.registerService(this.tendersSearchService);
    effect(() => {
      if (this.tendersModuleService.groupAccountStatuses().length) {
        if (!this.status.length)
          this.tendersSearchService.selectedStatusIds = this.tendersModuleService.groupAccountStatuses()
            .map((status) => status.id).concat(null);
        this.status = this.tendersModuleService.defaultStatusItems.concat({
          label: "Aucun",
          value: new StatusItem({
            id: null,
            displayed_status_name: "Aucun",
            status_name: "aucun",
            status_type: "default",
            backgroundColor: "#ffffff",
            color: "#6D82AA"
          })
        }, this.tendersModuleService.customStatusItems)
      }
    });
  }

  async ngOnInit() {
    this.tendersSearchService._filters$
      .pipe(
        filter((filters) => filters?.propagate ?? true),
        debounceTime(200),
        throttleTime(2000, undefined, {leading: true, trailing: true})
      )
      .subscribe(async (filters) => {
        if (
          this.tendersSearchService.filters?.territories?.length && (this.tendersSearchService.filters?.topics?.length || this.tendersSearchService.filters?.text?.length || this.tendersSearchService.filters?.status_ids?.length) &&
          this.tendersSearchService.filters.indexation_period?.from && this.tendersSearchService.filters.indexation_period.to && this.tendersSearchService.filters.tender_types?.length
        ) {
          const countByStatusBody = new PostTenderSearchBody(
            this.tendersSearchService.filters.topics,
            this.tendersSearchService.filters.text,
            this.tendersSearchService.filters.refined_search_text,
            this.tendersSearchService.filters.territories,
            this.tendersSearchService.filters.indexation_period,
            this.tendersSearchService.filters.estimated_end_period,
            this.tendersSearchService.filters.tender_types,
            [...this.tendersModuleService.statusItems.map((status) => status.value.id), null],
            this.tendersSearchService.filters.assigned_user_ids,
            this.tendersSearchService.filters.offset ?? 0,
            this.tendersSearchService.filters.limit ?? 25,
            this.tendersSearchService.filters.sort ?? COUNT_DEFAULT_SORT
          )
          countByStatusBody.service = ApiSearchServiceEnum.COUNT_QUALIFIED_TENDERS;
          if (!['status', 'offset', 'limit', 'sorting'].includes(filters?.fromFilter ?? '')) this.tendersSearchService.statusCounts = undefined;
          const req = this.apiTenderService.search.retrieveCountByStatus(countByStatusBody).catch(() => []);
          this.statusCountsRequest = req;
          const countersById = await req
          if (req !== this.statusCountsRequest) return;
          this.tendersSearchService.statusCounts = this.status.map((item) => {
            return new StatusCountItem({
              status_id: item.value.id,
              count: countersById?.find((ct) => ct.status_id === item.value.id)?.count ?? 0
            });
          });
        }
      })
  }

  ngOnDestroy() {
    this.tendersModuleService.unregisterService(this.tendersSearchService);
  }

  periodSelected(event: { period: Period | undefined, field: "indexation_period" | "estimated_end_period" }) {
    if (!event.period) return;
    if (event.field === 'indexation_period') {
      this.tendersSearchService.selectedIndexationPeriod = event.period;
    } else if (event.field === 'estimated_end_period') {
      this.tendersSearchService.selectedEstimatedEndPeriod = event.period;
    }
  }

  protected readonly SortFieldEnum = SortFieldEnum;

  onRefineResults($event: string) {
    this.tendersSearchService.refinedSearchText = $event;
  }

  onFilterChange({event, value}: {
    event: 'territories'; value: string[];
  } | {
    event: 'types'; value: TenderTypesEnum[];
  } | {
    event: 'period'; value: Period;
  }) {
    switch (event) {
      case 'territories':
        this.userSettingsService.set('board-territories-filter', value);
        break;
      case 'types':
        this.userSettingsService.set('board-types-filter', value);
        break;
    }
  }
}
