import { Injectable, TemplateRef } from '@angular/core';
import { Tender } from '../models/tender';
import { ExportService } from '../../shared/services/export.service';
import { TendersSearchService } from './tenders-search.service';
import { ApiSearchServiceEnum, PostTenderSearchBody, PostTenderSearchWatchBody, SortFieldEnum } from '../models/tender-search-body';
import { formatDateAsYYYYMMDD } from '../../shared/helpers/date-helper';
import { SortDirEnum } from '../../common-explain/models/sort';
import { StatusItem } from '../models/tender-status';
import { ApiAnnotationService } from './api/annotation/api-annotation.service';
import { ApiTenderService } from './api/api-tender.service';
import { TendersModuleService } from './tenders-module.service';

@Injectable()
export class TendersActionService {
  selection?: Array<Tender>
  export?: { promise: Promise<void>, cancel: () => void }

  constructor(private exportService: ExportService, private apiAnnotationService: ApiAnnotationService,
              private apiTenderService: ApiTenderService, private tendersModuleService: TendersModuleService) { }

  select(tender: Tender) {
    if (!this.selection) this.selection = [];
    if (this.selection.includes(tender)) this.selection.splice(this.selection.indexOf(tender), 1);
    else this.selection.push(tender);
  }

  toggle(open?: boolean) {
    if (open === undefined) open = !this.selection;
    if (open) this.selection = [];
    else this.selection = undefined;
  }

  buildFilters(tendersSearchService: TendersSearchService) {
    const filters = tendersSearchService.filters!;
    return {
      user_token: localStorage.getItem('api_token'),
      user_id: localStorage.getItem('user_id'),
      service: filters.market_watch_id ? ApiSearchServiceEnum.SEARCH_MARKET_WATCH_TENDERS :
        (filters.status_ids ? ApiSearchServiceEnum.SEARCH_QUALIFIED_TENDERS :
          (filters.text ? ApiSearchServiceEnum.SEARCH_TEXT : ApiSearchServiceEnum.SEARCH_TOPICS)),
      sort: {
        field: "closing_date",
        dir: "asc"
      },
      filters: {
        uids: this.selection?.map((tender) => tender.id),
      }
    }
  }

  buildSearchBody(tendersSearchService: TendersSearchService) {
    let searchBody;
    const filters = tendersSearchService.filters;
    if (!filters || !filters.indexation_period || !filters.territories) return;
    if (filters.market_watch_id) {
      searchBody = new PostTenderSearchWatchBody(
        filters.topics,
        filters.market_watch_id,
        formatDateAsYYYYMMDD(filters.indexation_period.to),
        filters.offset ?? 0,
        filters.limit ?? 25,
        filters.sort ?? {dir: SortDirEnum.DESC, field: SortFieldEnum.INDEXATION_DATE},
      )
    } else {
      searchBody = new PostTenderSearchBody(
        filters.topics,
        filters.text,
        filters.refined_search_text,
        filters.territories,
        filters.indexation_period,
        filters.estimated_end_period,
        filters.tender_types,
        filters.status_ids,
        filters.assigned_user_ids,
        filters.offset ?? 0,
        filters.limit ?? 25,
        filters.sort ?? {dir: SortDirEnum.DESC, field: SortFieldEnum.INDEXATION_DATE}
      )
    }
    return searchBody;
  }

  exportSelection(tendersSearchService: TendersSearchService, toast: TemplateRef<any>) {
    if (!this.selection?.length) return;
    this.export = this.exportService.export(
      this.buildFilters(tendersSearchService),
      {template: toast, options: {classname: 'toast-export-in-progress toast-shape', id: 'list-export'}}
    )
    this.export.promise.finally(() => this.export = undefined);
    this.toggle(false);
  }

  exportAll(tendersSearchService: TendersSearchService, toast: TemplateRef<any>) {
    if (!tendersSearchService.tenders?.length) return;
    this.export = this.exportService.export(
      this.buildSearchBody(tendersSearchService),
      {template: toast, options: {classname: 'toast-export-in-progress toast-shape', id: 'list-export'}}
    )
    this.export.promise.finally(() => this.export = undefined);
    this.toggle(false);
  }

  async tag(tenders: Tender[], status: StatusItem, tendersSearchService?: TendersSearchService) {
    if (!tenders.length) return;
    const requests = [];
    for (const tender of tenders) {
      if (tender.tenderAnnotationsId)
        requests.push(this.apiAnnotationService.updateAnnotation({status_id: status.id, id: tender.tenderAnnotationsId}).then(() => {
          tendersSearchService?.updateStatusCount({from: tender.statusId ?? null, to: status.id});
          this.tendersModuleService.updateTenderInRegisteredServices(tender.id, {statusId: status.id ?? undefined})
        }))
      else if(status.id) {
        requests.push(this.apiAnnotationService.addAnnotation({tender_uid: tender.id, status_id: status.id}).then(
          ({tender_annotation_id}) => {
            tendersSearchService?.updateStatusCount({from: tender.statusId ?? null, to: status.id});
            this.tendersModuleService.updateTenderInRegisteredServices(
              tender.id, {statusId: status.id ?? undefined, tenderAnnotationsId: tender_annotation_id}
            )
          }
        ));
      }
    }
    await Promise.all(requests);
    this.toggle(false);
  }

  async retrieveTenders(tendersSearchService: TendersSearchService, limit: number) {
    const searchBody = this.buildSearchBody(tendersSearchService);
    if (searchBody) {
      searchBody.limit = limit;
      const { data: tenders } = await this.apiTenderService.search.retrieveTenders(searchBody);
      // Concatenate visible tenders with retrieved tenders and deduplicate them
      return [
        ...new Map(
          (tendersSearchService.tenders ?? []).concat(tenders).map(tender => [tender.id, tender])
        ).values()
      ];
    }
    return [];
  }
}
